import React, { useMemo, useState } from 'react'
import { getCurrentWeek, getWeekArray } from '@utils/time.js'

import { buildFilterString } from '@utils/filterString'

import IMaintenance from '@interfaces/IMaintenance.d'
import IUser from '@interfaces/IUser.d'

// create contexts
const MaintenancesContextState = React.createContext(null)

const useMaintenancesContextState = () => {
  const context = React.useContext(MaintenancesContextState)

  if (context === undefined) {
    throw new Error('useTaskContextState was used outside of its Provider')
  }
  return context
}

interface MaintenancesContextProviderProps {
  children:            React.ReactNode
  current_user:        IUser
  serverMaintainables: IMaintenance[]
  initialPagination: {
    page: number
  }
  companyStartWeek: 0 | 1 | 2 | 3 | 4 | 5 | 6
  permissions:      object
}

const MaintenancesContextProvider: React.FC<MaintenancesContextProviderProps> = ({
  children,
  current_user,
  serverMaintainables,
  initialPagination,
  companyStartWeek,
  permissions
}) => {

  const MAINTENANCE_CONSTANTS = {
    VIEWS: {
      MAINTAINABLES: 'maintainables',
      PLANS:         'plans',
      MAINTENANCES:  'maintenances',
      TICKETS:       'tickets',
      PLANS_TABLE:   'plans_table'
    }
  }

  const startDayOfWeek = companyStartWeek || 1

  const [filters, setFilters]             = useState({
    view:              new URLSearchParams(window.location.search).get('view')                  || current_user?.preferences?.maintenance?.view || MAINTENANCE_CONSTANTS.VIEWS.MAINTAINABLES,
    year:              Number.parseInt(new URLSearchParams(window.location.search).get('year')) || new Date().getFullYear(),
    late:              new URLSearchParams(window.location.search).get('late')                  || null,
    legal:             new URLSearchParams(window.location.search).get('legal')                 || null,
    autogenerate:      new URLSearchParams(window.location.search).get('autogenerate')          || null,
    archived:          new URLSearchParams(window.location.search).get('archived')              || null,
    amenity_type_id:   new URLSearchParams(window.location.search).get('amenity_type_id')       || null,
    amenity_type_name: new URLSearchParams(window.location.search).get('amenity_type_name')     || null,
    plan_id:           new URLSearchParams(window.location.search).get('plan_id')               || null,
    plan_name:         new URLSearchParams(window.location.search).get('plan_name')             || null,
    zone_id:           null,
    zone_name:         null,
    amenity_id:        null,
    amenity_name:      null,
  })

  const isCurrentYear = useMemo(() => filters.year === new Date().getFullYear(), [filters.year])

  const [maintainables, setMaintainables] = useState(serverMaintainables || [])
  const [currentWeek,   setCurrentWeek]   = useState(isCurrentYear ? getCurrentWeek() : null)
  const [weekArray,     setWeekArray]     = useState(getWeekArray(filters.year, startDayOfWeek))
  const [weekCount,     setWeekCount]     = useState(Object.keys(weekArray).length)

  return (
    <MaintenancesContextState.Provider value={{
      MAINTENANCE_CONSTANTS,
      permissions, current_user,
      initialPagination, startDayOfWeek,
      maintainables, setMaintainables,
      filters, setFilters, buildFilterString,
      isCurrentYear,
      currentWeek, setCurrentWeek,
      weekArray, setWeekArray,
      weekCount, setWeekCount,
    }}>
      {children}
    </MaintenancesContextState.Provider>
  )
}

export { MaintenancesContextProvider, useMaintenancesContextState }
