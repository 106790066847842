import styled, { keyframes } from 'styled-components'

export const CheckMarkKeyFrames = keyframes`
  0%    { transform: scale(0)   }
  80%   { transform: scale(1.1) }
  100%  { transform: scale(1)   }
`

export const CheckboxContainer = styled.div`
  position:    relative;
  display:     flex;
  gap:         8px;
  align-items: center;
  margin-top: ${props => {
    switch(props.marginY) {
      case 'S':  return 'var(--rep-spacer-s)'
      case 'M':  return 'var(--rep-spacer-m)'
      case 'L':  return 'var(--rep-spacer-l)'
      case 'XL': return 'var(--rep-spacer-xl)'
      default:   return '0'
    }
  }};
  margin-bottom: ${props => {
    switch(props.marginY) {
      case 'S':  return 'var(--rep-spacer-s)'
      case 'M':  return 'var(--rep-spacer-m)'
      case 'L':  return 'var(--rep-spacer-l)'
      case 'XL': return 'var(--rep-spacer-xl)'
      default:   return '0'
    }
  }};
`

export const Checkbox = styled.div`
  border:           1px solid ${props => props.checked ? 'var(--rep-primary-light)' : props.color};
  background:       ${props => props.checked ? props.background : 'initial'};
  color:            ${props => props.color};
  border-radius:    4px;
  width:            20px;
  height:           20px;
  min-width:        20px;
  min-height:       20px;
  display:          flex;
  justify-content:  center;
  align-items:      center;
  background:       ${props => props.disabled ? 'var(--rep-neutral-light)' : null};
  &:hover {
    cursor: pointer;
    border: 1px solid ${props => props.color};
    background:       ${props => props.background};
  }
`

export const CheckMark = styled.div`
  color:                      ${props => props.markColor};
  display:                    ${props => props.displayed ? 'block' : 'none' };
  animation-name:             ${props => props.displayed ? CheckMarkKeyFrames : null};
  animation-iteration-count:  ${props => props.displayed ? '1' : '0'};
  animation-duration:         .3s;
  transform:                  scale(1);
`

export const CheckboxLabel = styled.div`
  margin-bottom: 0;
  font-size:     1rem;
  width:  ${props => props.tooltip && !props.fullWidth ? 'calc(100% - 40px)' : props.fullWidth ? '100%' : 'initial'};
  color:  ${props => props.color || 'var(--rep-primary)'};
  &:hover { cursor: pointer }
`
