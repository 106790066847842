import styled, { keyframes } from 'styled-components'

const AlertPopAnimation = keyframes`
  0%   { transform: scale(0);    visibility: hidden;  }
  90%  { transform: scale(1.02); visibility: visible; }
  100% { transform: scale(1);    visibility: visible; }
`

const AlertPopOutAnimation = keyframes`
  0%   { opacity: 1; visibility: visible; }
  100% { opacity: 0; visibility: hidden;  }
`

const AlertOverlayPopAnimation = keyframes`
  0%   { opacity: 0;  visibility: visible; }
  100% { opacity: .7; visibility: visible; }
`

const AlertOverlayPopOutAnimation = keyframes`
  0%   { opacity: 0.7; visibility: visible; }
  100% { opacity: 0;   visibility: hidden;  }
`

export const Alert = styled.div`
  visibility:                hidden;
  position:                  fixed;
  top:                       20%;
  left:                      25%;
  width:                     50%;
  z-index:                   2000;
  padding:                   40px;
  background:                white;
  border-radius:             8px;
  transform:                 scale(0);
  box-shadow:                0 0 24px rgba(0,0,0,.2);
  animation-name:            ${props => props.displayed ? AlertPopAnimation : AlertPopOutAnimation};
  animation-timing-function: ${props => props.displayed ? 'cubic-bezier(0,1.2,1,1.2)' : 'ease-out' };
  animation-iteration-count: 1;
  animation-duration:        .4s;
  animation-fill-mode:       forwards;
`

export const AlertContent = styled.div`
  text-align: center;
`

export const AlertActions = styled.div`
  display:         flex;
  justify-content: center;
  align-items:     center;
  margin-top:      16px;
  gap:             8px;
`

export const AlertOverlay = styled.div`
  visibility:                hidden;
  position:                  fixed;
  top:                       0;
  left:                      0;
  height:                    100vh;
  width:                     100vw;
  background:                #060e14;
  opacity:                   65%;
  z-index:                   2000;
  animation-name:            ${props => props.displayed ? AlertOverlayPopAnimation : AlertOverlayPopOutAnimation};
  animation-timing-function: ${props => props.displayed ? 'ease-in' : 'ease-out' };
  animation-iteration-count: 1;
  animation-duration:        ${props => props.displayed ? '.2s' : '.5s' };
  animation-fill-mode:       forwards;
`
