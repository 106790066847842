import React, { useCallback, useMemo, useReducer, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Avatar       from '@components/Avatar'
import AvatarList   from '@components/AvatarList'
import Button       from '@components/Button'
import Callout      from '@components/Callout'
import Card         from '@components/Card'
import CostForm     from '@components/Cost/Form'
import DoneForm     from './DoneForm'
import Dropdown     from '@components/Dropdown'
import FormQuestion from '@components/FormQuestion'
import RequestForm  from '@components/Material/Request/RequestForm'
import Tag          from '@components/Tag'
import TaskForm     from './Form'
import Thumbnail    from '@form/FileUploader/Thumbnail'
import WorkPeriod   from '@components/WorkPeriod'

import * as Style from './style'

import { TaskContextProvider }   from '@context/TaskContext'
import { useGlobalContextState } from '@context/GlobalContext'

import { COST_ACTIONS, TASK_ACTIONS, costReducer, taskReducer } from '@reducers/index'

import useHumanMs from '@hooks/useHumanMs'

import { TaskProps } from './types.d'

const Task: React.FC<TaskProps> = ({
  report,
  task,
  updateMethods,
}) => {

  const {
    initAPI,
    fetchApi,
    current_user,
    current_company,
    showModal,
    closeModal,
    showAlert,
    closeAlert,
    i18n,
    CONSTANTS
  } = useGlobalContextState()

  const { TASK, COST } = CONSTANTS

  const [opened,    setOpened]    = useState(false)
  const [animation, setAnimation] = useState(null)

  const API = initAPI({
    reportId:      report.id,
    taskId:        task.id,
    updateMethods: updateMethods
  })

  // Initialize reducer with the actions.
  // State is not used in component as the data comes from the context.
  const [_state, dispatch]         = useReducer(taskReducer, { report, task, API, updateMethods, setAnimation, fetchApi, closeModal })
  const [_costState, costDispatch] = useReducer(costReducer, { report, task, API, updateMethods, setAnimation, fetchApi, closeModal })

  // Task reducer
  const selfAssignTask = ()   => dispatch({ type:      TASK_ACTIONS.SELF_ASSIGN, callbacks: [
    () => API.fetchMinimalReport().then(updateMethods.minimalReport)
  ] })
  const startTask      = ()   => dispatch({ type:      TASK_ACTIONS.START,   animation: 'loading', callbacks: [
    () => API.fetchMinimalReport().then(updateMethods.minimalReport),
    () => API.fetchCosts().then(updateMethods.costs)
  ]})
  const stopTask       = ()   => dispatch({ type:      TASK_ACTIONS.STOP,    animation: 'loading', callbacks: [
    () => API.fetchCosts().then(updateMethods.costs)
  ]})
  const openTask       = ()   => dispatch({ type: TASK_ACTIONS.OPEN,    animation: 'loading' })
  const approveTask    = ()   => dispatch({ type: TASK_ACTIONS.APPROVE, animation: 'loading' })
  const destroyTask    = ()   => dispatch({ type:      TASK_ACTIONS.DESTROY, animation: 'loading', callbacks: [
    closeAlert,
    () => API.fetchMinimalReport().then(updateMethods.minimalReport)
  ] })
  const updateTask     = data => dispatch({ type:      TASK_ACTIONS.UPDATE,  data, callbacks: [
    () => API.fetchConversations().then(updateMethods.conversations),
    () => API.fetchMinimalReport().then(updateMethods.minimalReport),
    closeModal
  ]})
  const doneTask       = data => dispatch({ type:      TASK_ACTIONS.DONE,    data, animation: 'loading', callbacks: [
    () => API.fetchMinimalReport().then(updateMethods.minimalReport),
    () => API.fetchCosts().then(updateMethods.costs),
    closeModal
  ]})

  const createCost     = data => costDispatch({ type:      COST_ACTIONS.CREATE, data, callbacks: [
    () => API.fetchMinimalReport().then(updateMethods.minimalReport)
  ] })

  const deletePeriod   = period          => dispatch({ type:      TASK_ACTIONS.DELETE_PERIOD, period, callbacks: [
    () => API.fetchMinimalReport().then(updateMethods.minimalReport)
  ] })
  const updatePeriod   = (period, dates, time) => dispatch({ type: TASK_ACTIONS.UPDATE_PERIOD, period, dates, time })
  const answerQuestion = (answer, reply)       => dispatch({ type: TASK_ACTIONS.ANSWER_QUESTION, answer, reply, task })

  const showDoneForm = useCallback(() => showModal({
    title:   i18n.t('todo.actions.close'),
    content: <TaskContextProvider
      key        = {task.id}
      serverTask = {task}
    >
      <DoneForm
        callback = {doneTask}
        report   = {report}
      />
    </TaskContextProvider>
  }), [task])

  const showTaskForm = useCallback(() => showModal({
    title:   i18n.t('todo.actions.edit_task'),
    content: <TaskForm
      task         = {task}
      assignTo     = 'internal'
      report       = {report}
      updateTask   = {updateTask}
      updatePeriod = {updatePeriod}
      deletePeriod = {deletePeriod}
    />
  }), [task])

  const showCostForm = useCallback(type => showModal({
    title:   i18n.t(`offer_line.new_${current_user.managing ? 'cost' : 'activity'}`),
    content: <CostForm
      task       = {task}
      type       = {type}
      report     = {report}
      createCost = {createCost}
    />
  }), [task])

  const showQuestionsForm = useCallback(reply =>
    showModal({
      title:   reply.name,
      content: <>
        {reply.questions.map(question =>
          <FormQuestion
            key            = {question.id}
            question       = {question}
            form           = {reply}
            answerQuestion = {answerQuestion}
          />
        )}
        {reply.complete &&
        <Callout
          border     = 'var(--rep-success)'
          background = 'var(--rep-success-light)'
          color      = 'var(--rep-success)'
          icon       = {<FontAwesomeIcon icon="check" />}
        >
          Formulaire complété !
        </Callout>
        }
      </>
    }), [task, task.forms])

  const actions = useMemo(() => {
    const actionsArray = []
    // Material requests
    if (report.permissions.can_create_material_request) {
      actionsArray.push({
        icon:      <FontAwesomeIcon icon="code-pull-request" />,
        color:     'var(--rep-neutral-primary)',
        content:   i18n.t('material_request.material_request'),
        separator: true,
        click:     () => showModal({
          title:   i18n.t('material_request.actions.prepare_request'),
          content: <RequestForm report={report} task={task}/>,
        })
      })
      actionsArray.push({ separator: true })
    }

    // COSTS FORMS
    if (current_company.permissions.can_see_costs && task.permissions.can_create_cost) {
      actionsArray.push({
        icon:    <FontAwesomeIcon icon="user-clock" />,
        content: i18n.t('offer_line.actions.add_cost_hours'),
        click:   () => showCostForm(COST.TYPE.HOURS)
      })
      actionsArray.push({
        icon:    <FontAwesomeIcon icon="truck-moving" />,
        content: i18n.t('offer_line.actions.add_cost_transport'),
        click:   () => showCostForm(COST.TYPE.TRANSPORT)
      })
      if (current_company.permissions.access_stock) {
        actionsArray.push({
          icon:    <FontAwesomeIcon icon="cubes" />,
          content: i18n.t('offer_line.actions.add_cost_material'),
          click:   () => showCostForm(COST.TYPE.INVENTORY)
        })
      }
      actionsArray.push({
        icon:    <FontAwesomeIcon icon="file-invoice-dollar" />,
        content: i18n.t('offer_line.actions.add_cost_external_invoice'),
        click:   () => showCostForm(COST.TYPE.EXTERNAL_INVOICE)
      })
      actionsArray.push({ separator: true })
    }

    // TASK ACTIONS
    if (task.permissions.done_task) actionsArray.push({
      icon:       <FontAwesomeIcon icon="check" />,
      color:      'var(--rep-success)',
      background: 'var(--rep-success-light)',
      content:    i18n.t('todo.actions.close_task'),
      click:      showDoneForm
    })
    if (task.permissions.open_task) actionsArray.push({
      icon:       <FontAwesomeIcon icon="lock-open" />,
      color:      'var(--rep-warning)',
      background: 'var(--rep-warning-light)',
      content:    i18n.t('todo.actions.reopen_task'),
      click:      openTask
    })
    if (task.permissions.update_task) actionsArray.push({
      icon:       <FontAwesomeIcon icon="edit" />,
      color:      'var(--rep-primary)',
      background: 'var(--rep-primary-light)',
      content:    i18n.t('actions.edit'),
      click:      showTaskForm
    })
    if (task.permissions.destroy_task) actionsArray.push({
      icon:    <FontAwesomeIcon icon="trash-can" />,
      color:   'var(--rep-danger)',
      content: i18n.t('actions.destroy'),
      click:   () => showAlert(
        <Callout type="danger" icon={<FontAwesomeIcon icon="triangle-exclamation" />}>
          {i18n.t('actions.delete_confirm')}
        </Callout>,
        [{
          content:    i18n.t('actions.delete'),
          color:      'white',
          background: 'var(--rep-danger)',
          icon:       <FontAwesomeIcon icon="trash-can" />,
          click:      destroyTask
        }]
      )
    })

    return actionsArray
  }, [task])

  // START/STOP BUTTON
  const startStopAction = useMemo(() => {
    if (task.action === TASK.ACTIONS.START && task.permissions.start_task) {
      return <Style.StartStop
        onClick = {startTask}
        status  = 'start'
      >
        <FontAwesomeIcon icon="play" /><span>start</span>
      </Style.StartStop>
    }

    if (task.action === TASK.ACTIONS.STOP  && task.permissions.stop_task) {
      return <Style.StartStop
        onClick = {stopTask}
        status  = 'stop'
      >
        <FontAwesomeIcon icon="stop" /><span>stop</span>
      </Style.StartStop>
    }
  }, [task.action, task.permissions])

  const doneApproveAction = useMemo(() => {
    const result = []
    if (task.status === TASK.STATUS.NEW && current_company.permissions.tech_can_create_tasks) result.push(
      <Button
        key   = 'take_task'
        icon  = {<FontAwesomeIcon icon="handshake" />}
        click = {selfAssignTask}
        color = 'var(--rep-primary)'
        hover = 'var(--rep-primary-light)'
      >
        {i18n.t('todo.actions.take_task')}
      </Button>
    )
    if (task.status === TASK.STATUS.NEW && report.permissions.can_assign_task) result.push(
      <Button
        key   = 'assign'
        icon  = {<FontAwesomeIcon icon='user-plus' />}
        color = 'var(--rep-primary)'
        hover = 'var(--rep-primary-light)'
        click = {showTaskForm}
      >
        {i18n.t('todo.actions.assign_task')}
      </Button>
    )
    if (task.permissions.done_task && (task.status !== TASK.STATUS.NEW)) result.push(
      <Button
        key   = 'action-done'
        icon  = {<FontAwesomeIcon icon='check' />}
        color = 'var(--rep-success)'
        hover = 'var(--rep-success-light)'
        click = {showDoneForm}
      >
        {i18n.t('todo.actions.close_task')}
      </Button>
    )
    if (task.permissions.approve_task) result.push(
      <Button
        key   = 'action-approve'
        icon  = {<FontAwesomeIcon icon="check-double" />}
        color = 'var(--rep-success)'
        hover = 'var(--rep-success-light)'
        click = {approveTask}
      >
        {i18n.t('todo.actions.approve_task')}
      </Button>
    )
    return result
  }, [task])

  const statusIcon = ({
    [TASK.STATUS.NEW]:      <FontAwesomeIcon fixedWidth icon={['far', 'paper-plane']} />,
    [TASK.STATUS.ONGOING]:  <FontAwesomeIcon fixedWidth icon={['far', 'circle']} beatFade />,
    [TASK.STATUS.ASSIGNED]: <FontAwesomeIcon fixedWidth icon={['far', 'user']} />,
    [TASK.STATUS.DONE]:     <FontAwesomeIcon fixedWidth icon={['far', 'eye']} />,
    [TASK.STATUS.CLOSED]:   <FontAwesomeIcon fixedWidth icon='check' />
  })[task.status] ?? <FontAwesomeIcon fixedWidth icon={['far','paper-plane']} />

  return (
    <Card
      togglable = {task.status !== TASK.STATUS.NEW}
      opened    = {opened}
      setOpened = {setOpened}
      animation = {animation}
      border    = {'var(--rep-neutral-primary-light)'}
      headerTop = {
        <Style.TaskHeader>
          <Style.TaskStatus status={task.status}>
            {statusIcon}
            {i18n.t(`todo.status.${task.status}`)}
          </Style.TaskStatus>

          <Style.TaskTopActions>
            {!!task.material_requests.length
              && task.material_requests.map(request =>
                <Tag
                  key            = {request.id}
                  icon           = {<FontAwesomeIcon icon='boxes-stacked' />}
                  color          = {request.status === 'requested' ? 'var(--rep-warning)' : request.status === 'draft' ? 'var(--rep-neutral)' : 'var(--rep-success)'}
                  background     = {request.status === 'requested' ? 'var(--rep-warning-light)' : request.status === 'draft' ? 'var(--rep-neutral-light)' : 'var(--rep-success-light)'}
                  hover          = {request.status === 'requested' ? 'var(--rep-warning-light)' : request.status === 'draft' ? 'var(--rep-neutral-light)' : 'var(--rep-success-light)'}
                  tooltipContent = {`[${request.status === 'draft' ? i18n.t('material_request.status.draft') : request.number}] Materials requested by ${request.requester.name}`}
                  click          = {() => window.open(`/material_requests/${request.id}`, '_blank', 'noopener,noreferrer')}
                />
              )}
            {current_company.permissions.see_estimated_time && !!task.duration &&
                <Tag
                  icon		       = {<FontAwesomeIcon icon="hourglass" />}
                  color          = 'var(--rep-neutral-primary)'
                  background     = 'var(--rep-neutral-light)'
                  hover          = 'var(--rep-neutral-primary-light)'
                  tooltipContent = {i18n.t('todo.expected_duration')}
                >
                  {useHumanMs(task.duration)}
                </Tag>
            }
            {!!task.planned_end &&
              <Tag
                icon           = {<FontAwesomeIcon icon={['far', 'calendar']} />}
                color          = 'var(--rep-neutral-primary)'
                background     = 'var(--rep-neutral-light)'
                hover          = 'var(--rep-neutral-primary-light)'
                tooltipContent = {i18n.t('todo.expected_date')}
              >
                {new Date(task.planned_end).toLocaleDateString(i18n.locale)}
              </Tag>
            }
            <Style.Avatars>
              {!!task.done_by &&
                <Avatar
                  firstName  = {task.done_by.first_name}
                  lastName   = {task.done_by.last_name}
                  background = 'var(--rep-neutral-light)'
                  color      = 'var(--rep-neutral)'
                  title      = {i18n.t('todo.done_on', {date: new Date(task.done_date).toLocaleDateString(i18n.locale)})}
                />
              }
              {!!task.approved_by &&
                <Avatar
                  firstName  = {task.approved_by.first_name}
                  lastName   = {task.approved_by.last_name}
                  background = 'var(--rep-success-light)'
                  color      = 'var(--rep-success)'
                  title      = {i18n.t('todo.approved_on', {date: new Date(task.approval_date).toLocaleDateString(i18n.locale)})}
                />
              }
            </Style.Avatars>
            {!!actions.length &&
              <Dropdown
                options   = {actions}
                withArrow = {false}
                color     = 'var(--rep-primary)'
                icon      = {<FontAwesomeIcon icon="cog" />}
              />
            }
          </Style.TaskTopActions>
        </Style.TaskHeader>
      }
      header = {
        <>
          <Style.TaskTitle>{task.title}</Style.TaskTitle>
          {task.status !== TASK.STATUS.CLOSED && !!task.description &&
            <Style.TaskText>
              {task.description}
            </Style.TaskText>
          }
        </>
      }
      bodyReduced = {
        <Style.TaskActions>
          {!!task.work_periods.length && task.status !== TASK.STATUS.CLOSED &&
            <AvatarList
              users = {task.work_periods?.map(wp => wp.user)}
              limit = {3}
            />
          }

          {doneApproveAction}
        </Style.TaskActions>
      }
      bodyExpanded = {
        <>
          {task.status === TASK.STATUS.CLOSED && !!task.description &&
            <Style.TaskText>{task.description}</Style.TaskText>
          }

          {task.status === TASK.STATUS.CLOSED &&
            current_company.permissions.can_see_forms &&
            !!task.forms.length &&
              <Style.TaskForms>
                {task.forms.map(reply =>
                  <Button
                    key        = {reply.id}
                    click      = {() => showQuestionsForm(reply)}
                    icon       = {reply.complete ? <FontAwesomeIcon icon="check" /> : <FontAwesomeIcon icon="clipboard-question" />}
                    color      = {reply.complete ? 'var(--rep-success)'       : 'var(--rep-neutral-primary)'}
                    background = {reply.complete ? 'none'                     : 'var(--rep-neutral-primary-light)'}
                    hover      = {reply.complete ? 'var(--rep-success-light)' : 'var(--rep-neutral-primary-light)'}
                    border     = {reply.complete ? 'var(--rep-success-light)' : 'var(--rep-neutral-primary-light)'}
                  >
                    {reply.name}
                  </Button>
                )}
              </Style.TaskForms>
          }

          {task.work_periods.map(period =>
            <WorkPeriod
              key    = {period.id}
              task   = {task}
              period = {period}
            />
          )}

          <Style.TaskActions>
            <div></div>
            {doneApproveAction}
          </Style.TaskActions>
        </>
      }
    >
      {startStopAction}
      {[TASK.STATUS.DONE, TASK.STATUS.CLOSED].includes(task.status) && !!task.comment &&
        <Callout
          title      = {i18n.t('todo.closing_comment_from', { user: task.done_by?.name })}
          icon       = {<FontAwesomeIcon icon="comment-dots" />}
          color      = "var(--rep-neutral-primary)"
          border     = "transparent"
          background = "none"
        // marginY    = 'S'
        >
          {task.comment}
        </Callout>
      }
      {[TASK.STATUS.DONE, TASK.STATUS.CLOSED].includes(task.status) && !!task.documents.length &&
        <Style.FileListStyles>
          {task.documents.map(doc =>
            <Thumbnail
              key  = {doc.id}
              file = {doc}
            />
          )}
        </Style.FileListStyles>
      }
      {task.status !== TASK.STATUS.CLOSED && current_company.permissions.can_see_forms && !!task.forms.length &&
        <Style.TaskForms>
          {task.forms.map(reply =>
            <Button
              key        = {reply.id}
              click      = {() => showQuestionsForm(reply)}
              icon       = {reply.complete ? <FontAwesomeIcon icon="check" /> : <FontAwesomeIcon icon="clipboard-question" />}
              color      = {reply.complete ? 'var(--rep-success)'       : 'var(--rep-neutral-primary)'}
              background = {reply.complete ? 'none'                     : 'var(--rep-neutral-primary-light)'}
              hover      = {reply.complete ? 'var(--rep-success-light)' : 'var(--rep-neutral-primary-light)'}
              border     = {reply.complete ? 'var(--rep-success-light)' : 'var(--rep-neutral-primary-light)'}
            >
              {reply.name}
            </Button>
          )}
        </Style.TaskForms>
      }
    </Card>
  )
}

export default Task
