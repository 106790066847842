import React, { useMemo, useReducer, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Checkbox   from '@form/Checkbox'
import DatePicker from '@form/DatePicker'
import FieldInput from '@form/FieldInput'
import Input      from '@form/Input'
import Select     from '@form/Select'
import SelectTree from '@form/SelectTree/SelectTree'
import Tag        from '@components/Tag'
import Textarea   from '@form/Textarea'

import { useGlobalContextState } from '@context/GlobalContext'

import * as FormStyle from '@form/FormStyles'

import IReport from '@interfaces/IReport.d'

import { REPORT_ACTIONS, reportReducer } from '@reducers/index'

interface ReportFormProps {
  report:        IReport,
  updateMethods: object
}

const ReportForm: React.FC<ReportFormProps> = ({
  report,
  updateMethods
}) => {

  const {
    i18n,
    fetchApi,
    current_user,
    closeModal,
    CONSTANTS
  } = useGlobalContextState()

  // React.Dispatch<any>
  const [_state, dispatch] = useReducer(reportReducer, { report, fetchApi, updateMethods, closeModal })

  const createReport = data => dispatch({ type: REPORT_ACTIONS.CREATE, data })
  const updateReport = data => dispatch({ type: REPORT_ACTIONS.UPDATE, data, report })

  const { REPORT }      = CONSTANTS
  const { ROLES }       = CONSTANTS.USER
  const { TYPE_VALUES } = CONSTANTS.REPORT_TYPES

  const [urgentChecked,       setUrgentChecked]       = useState({ value: 'urgent', isCheck: report.urgent })
  const [expectedDate,        setExpectedDate]        = useState(report.expected_date ? new Date(report.expected_date) : '')
  const [categorySelected,    setCategorySelected]    = useState(report.category)
  const [responsibleSelected, setResponsibleSelected] = useState(report.responsible)
  const [reportType,          setReportType]          = useState(report.type)
  const [amenitySelected,     setAmenitySelected]     = useState(report.amenity)
  const [unitSelected,        setUnitSelected]        = useState(report.unit)
  const [fields,              setFields]              = useState(reportType.report_fields.map(field => {
    return {
      report_field_id: field.id,
      value:           report.field_values.find(f => f.report_field.id === field.id)?.value
    }
  }))

  const handleFieldChange = (fieldValue) => {
    const newFields = [...fields]
    newFields.find(field => field.report_field_id === fieldValue.field_id).value = fieldValue.value
    setFields(newFields)
  }

  const sendReport = event => {
    event.preventDefault()

    const formData: FormData = new FormData(event.currentTarget)
    formData.append('urgent',         urgentChecked?.isCheck?.toString())
    formData.append('category',       categorySelected?.id ? categorySelected?.id.toString() : '')
    formData.append('amenity',        amenitySelected?.id  ? amenitySelected?.id.toString()  : '')
    formData.append('unit',           unitSelected?.id     ? unitSelected?.id.toString()     : '')
    formData.append('control_remark', report?.remark?.id   ? report.remark.id.toString()     : '')
    formData.append('report_fields',  JSON.stringify(fields))

    const data = Object.fromEntries(formData)
    report?.id ? updateReport(data) : createReport(data)
  }

  const userFilter = useMemo(() => {
    return report.mandatory_expertise
      ? [{
        id:      'expertise',
        name:    categorySelected?.name,
        filters: { expertise: categorySelected?.id, role: report.type.manageable_roles }
      }]
      : [{
        id:      'role',
        name:    'Role',
        filters: { role: report.type.manageable_roles }
      }]
  }, [categorySelected])

  const changeReportType = type => setReportType(type.object)

  const selectCategory = response => {
    if (report.mandatory_expertise && response?.id !== categorySelected?.id) setResponsibleSelected(undefined)
    setCategorySelected(response?.object)
  }

  const selectUnit = unit => {
    setUnitSelected(unit)
    setAmenitySelected(undefined)
  }

  const fieldVisible = (field) => {
    switch (current_user.role) {
      case ROLES.EMPLOYEE_MANAGER:
        return field.visible_to_employee_managers
      case ROLES.EMPLOYEE:
        return field.visible_to_employees
      case ROLES.TECHNICIAN_MANAGER:
        return field.visible_to_technician_managers
      case ROLES.TECHNICIAN:
        return field.visible_to_technicians
      default:
        return true
    }
  }

  return(
    <form onSubmit={sendReport}>
      <FormStyle.Header><FontAwesomeIcon icon="circle-info" />{i18n.t('shared.general_informations')}</FormStyle.Header>
      <Input
        type         = 'text'
        name         = 'title'
        label        = {i18n.t('shared.title')}
        defaultValue = {report.title}
        disabled     = {report?.id && !report.permissions.can_update_report}
        marginY      = 'M'
        required     = {true}
      />
      {reportType.description !== TYPE_VALUES.HIDDEN &&
        <Textarea
          name         = 'description'
          label        = {i18n.t('shared.description')}
          disabled     = {report?.id && !report.permissions.can_update_description}
          defaultValue = {report.description}
          marginY      = 'M'
          required     = {reportType.description === TYPE_VALUES.REQUIRED}
        />
      }

      <FormStyle.Header><FontAwesomeIcon icon="passport" />{i18n.t('shared.category')}</FormStyle.Header>
      <Select
        label         = {i18n.t('report_type.report_type')}
        name          = 'report_type'
        search        = {true}
        searchUrl     = '/report_types/search'
        defaultValue  = {[reportType]}
        format        = {{ content: 'name', value: 'id' }}
        disabled      = {report?.id && !report.permissions.can_update_report}
        marginY       = 'M'
        required      = {true}
        callback      = {changeReportType}
      />

      { report?.id &&
        [REPORT.STATUS.NEW, REPORT.STATUS.ONGOING, REPORT.STATUS.ON_HOLD].includes(report.status) &&
          <Select
            label         = {i18n.t('report.report_status')}
            name          = 'status'
            defaultValue  = {[{ content: i18n.t(`report.status.${report.status}`), value: report.status }]}
            options       = {[
              { content: i18n.t(`report.status.${REPORT.STATUS.NEW}`),     value: REPORT.STATUS.NEW     },
              { content: i18n.t(`report.status.${REPORT.STATUS.ONGOING}`), value: REPORT.STATUS.ONGOING },
              { content: i18n.t(`report.status.${REPORT.STATUS.ON_HOLD}`), value: REPORT.STATUS.ON_HOLD },
            ]}
            format        = {{ content: 'content', value: 'value' }}
            required
            disabled      = {report?.id && !report.permissions.can_update_report}
            marginY       = 'M'
          />
      }

      {reportType.expertise !== TYPE_VALUES.HIDDEN &&
        <Select
          label         = {i18n.t('expertise.expertise')}
          name          = 'expertise'
          search        = {true}
          searchUrl     = '/expertises/search'
          withEmpty     = {true}
          callback      = {selectCategory}
          emptyCallback = {selectCategory}
          defaultValue  = {[categorySelected]}
          format        = {{ content: 'name', value: 'id' }}
          disabled      = {report?.id && !report.permissions.can_update_report}
          marginY       = 'M'
          required      = {report.mandatory_expertise || reportType.expertise === TYPE_VALUES.MANDATORY}
        />
      }

      {reportType.anomaly !== TYPE_VALUES.HIDDEN &&
        <Select
          label         = {i18n.t('anomaly.anomaly')}
          name          = 'anomaly'
          search        = {true}
          searchUrl     = '/anomalies/search'
          withEmpty     = {true}
          defaultValue  = {[report.anomaly]}
          format        = {{ content: 'name', value: 'id' }}
          disabled      = {report?.id && !report.permissions.can_update_report}
          marginY       = 'M'
          required      = {reportType.anomaly === TYPE_VALUES.MANDATORY}
          filters       = {[
            {
              id:      'report_type',
              name:    i18n.t('anomaly.anomalies'),
              filters: { report_type_id: `${report.type.id}` }
            }
          ]}
        />
      }

      {reportType.hh_name === CONSTANTS.REPORT.TYPE.RECYCLE &&
        <>
          <Select
            label         = {i18n.t('waste_category.waste_category')}
            name          = 'waste_category'
            search
            searchUrl     = '/waste_categories/search'
            withEmpty
            defaultValue  = {[report.waste_category]}
            format        = {{ content: 'description', value: 'id' }}
            disabled      = {report?.id && !report.permissions.can_update_report}
            marginY       = 'M'
          />
          <FormStyle.Header><FontAwesomeIcon icon="exclamation-triangle" />{i18n.t('report.danger')}</FormStyle.Header>
          <Checkbox defaultValue={report.danger_icons.includes('pressure')}      marginY='S' name='type_pressure'    label={i18n.t('offer_line.danger.pressure')} />
          <Checkbox defaultValue={report.danger_icons.includes('explosion')}     marginY='S' name='type_explosive'   label={i18n.t('offer_line.danger.explosive')} />
          <Checkbox defaultValue={report.danger_icons.includes('comburant')}     marginY='S' name='type_comburant'   label={i18n.t('offer_line.danger.comburant')} />
          <Checkbox defaultValue={report.danger_icons.includes('combustible')}   marginY='S' name='type_fuel'        label={i18n.t('offer_line.danger.fuel')} />
          <Checkbox defaultValue={report.danger_icons.includes('corrosif')}      marginY='S' name='type_corrosive'   label={i18n.t('offer_line.danger.corrosive')} />
          <Checkbox defaultValue={report.danger_icons.includes('nocif')}         marginY='S' name='type_nocive'      label={i18n.t('offer_line.danger.nocive')} />
          <Checkbox defaultValue={report.danger_icons.includes('toxique')}       marginY='S' name='type_toxic'       label={i18n.t('offer_line.danger.toxic')} />
          <Checkbox defaultValue={report.danger_icons.includes('santé')}         marginY='S' name='type_health'      label={i18n.t('offer_line.danger.health')} />
          <Checkbox defaultValue={report.danger_icons.includes('environnement')} marginY='S' name='type_environment' label={i18n.t('offer_line.danger.environment')} />
        </>
      }

      <FormStyle.Header><FontAwesomeIcon icon="users" />{i18n.t('user.users')}</FormStyle.Header>
      <Select
        label         = {i18n.t('shared.requested_by')}
        name          = 'requester'
        search        = {true}
        searchUrl     = '/users/search'
        defaultValue  = {[report?.requester || current_user]}
        format        = {{ content: 'name', details: 'role', value: 'id' }}
        disabled      = {report?.id && !report.permissions.can_update_report}
        marginY       = 'M'
        detailsLocaleKey = 'shared'
        required
      />

      {reportType.responsible !== TYPE_VALUES.HIDDEN &&
        <Select
          label            = {i18n.t('shared.responsible')}
          name             = 'responsible'
          search           = {true}
          searchUrl        = '/users/search'
          filters          = {userFilter}
          defaultFilter    = 'expertise'
          defaultValue     = {[responsibleSelected]}
          format           = {{ content: 'name', details: 'role', value: 'id' }}
          disabled         = {report?.id && !report.permissions.can_update_report}
          marginY          = 'M'
          detailsLocaleKey = 'shared'
          required         = {reportType.responsible === TYPE_VALUES.MANDATORY}
        />
      }

      <FormStyle.Header>
        <FontAwesomeIcon icon="location-dot" />
        {[CONSTANTS.REPORT.TYPE.RECYCLE, CONSTANTS.REPORT.TYPE.MOVE].includes(reportType.hh_name)
          ? i18n.t('report.drop_in')
          : i18n.t('shared.location')
        }
      </FormStyle.Header>
      <SelectTree
        name           = 'unit'
        label          = {i18n.t('unit.unit')}
        selected       = {unitSelected}
        callback       = {selectUnit}
        withEmpty      = {true}
        searchUrl      = '/units/rep_search'
        filters        = {[{
          id:      'root',
          name:    'Parent',
          filters: { roots: 'true' }
        }]}
        format         = {{ content: 'name', value: 'id' }}
        disabled       = {report?.id && !report.permissions.can_update_report}
        marginY        = 'M'
        subFilterName  = 'for_unit'
        subElementName = {i18n.t('unit.subunit')}
      />
      {unitSelected?.has_amenities && reportType.amenity !== TYPE_VALUES.HIDDEN &&
        <>
          <FormStyle.UnitArrow><FontAwesomeIcon icon="arrow-down" /></FormStyle.UnitArrow>
          <SelectTree
            name           = 'amenity'
            label          = {i18n.t('amenity.amenity')}
            selected       = {amenitySelected}
            callback       = {setAmenitySelected}
            withEmpty      = {true}
            required       = {reportType === TYPE_VALUES.MANDATORY}
            searchUrl      = '/amenities/rep_search'
            filters        = {[{
              id:      'amenity',
              name:    'Parent',
              filters: { roots: 'true', for_unit: unitSelected.id.toString() }
            }]}
            format         = {{ content: 'name', value: 'id' }}
            disabled       = {report?.id && !report.permissions.can_update_report}
            marginY        = 'M'
            subFilterName  = 'for_amenity'
            subElementName = {i18n.t('amenity.subamenity')}
          />
        </>
      }

      {[CONSTANTS.REPORT.TYPE.RECYCLE, CONSTANTS.REPORT.TYPE.MOVE].includes(reportType.hh_name) &&
        <>
          <FormStyle.Header><FontAwesomeIcon icon="truck-ramp-box" />{i18n.t('report.drop_off')}</FormStyle.Header>
          <Input
            type         = 'text'
            name         = 'drop_off'
            defaultValue = {report.drop_off}
            disabled     = {report?.id && !report.permissions.can_update_report}
            marginY      = 'M'
          />
        </>
      }

      {reportType.location !== TYPE_VALUES.HIDDEN &&
        <Input
          label        = {i18n.t('report.location_informations')}
          type         = "text"
          name         = "location"
          defaultValue = {report.location}
          required     = {reportType.location === TYPE_VALUES.MANDATORY}
          marginY      = 'M'
          // placeholder  = ''
        />
      }

      {reportType.charge_number !== TYPE_VALUES.HIDDEN &&
      <>
        <FormStyle.Header><FontAwesomeIcon icon="receipt" />{i18n.t('charge_number.charge_number')}</FormStyle.Header>
        <Select
          name          = 'charge_number'
          search        = {true}
          searchUrl     = '/charge_numbers/search'
          withEmpty     = {true}
          defaultValue  = {[report.input_code]}
          format        = {{ content: 'number', value: 'id', details: 'type' }}
          disabled      = {report?.id && !report.permissions.can_update_report}
          marginY       = 'M'
          required      = {reportType.charge_number === TYPE_VALUES.MANDATORY}
        />
      </>
      }

      {!!reportType.report_fields.length &&
        <>
          <FormStyle.Header><FontAwesomeIcon icon="layer-group" />{i18n.t('custom_field.custom_fields')}</FormStyle.Header>
          {reportType.report_fields.map((field) => {
            return (fieldVisible(field) && <FieldInput
              key          = {field.id}
              field        = {field}
              defaultValue = {report.field_values.find(f => f.report_field.id === field.id)?.value}
              marginY      = 'M'
              disabled     = {report?.id && !report.permissions.can_update_report}
              callback     = {handleFieldChange}
              required     = {field.mandatory === TYPE_VALUES.MANDATORY}
            />)
          })}
        </>
      }

      <FormStyle.Header><FontAwesomeIcon icon="calendar-days" />{i18n.t('shared.planning')}</FormStyle.Header>
      {report?.id && report?.deadline
        ? <div>
          <Tag
            background     = 'var(--rep-neutral-light)'
            color          = 'var(--rep-neutral-primary)'
            marginY        = 'M'
            icon           = {<FontAwesomeIcon icon="hand-sparkles" />}
            tooltipContent = {i18n.t('report.deadline')}
          >
            {i18n.t('report.deadline')}: {new Date(report.deadline).toLocaleDateString(i18n.locale, { weekday: 'long', month: 'short', day: 'numeric', year: 'numeric' })}
          </Tag>
        </div>
        : <DatePicker
          name = "deadline"
        />
      }

      {report?.id  && report.permissions.can_set_expected_date &&
            <DatePicker
              label        = {i18n.t('report.expected_date')}
              name         = 'expected_date'
              date         = {expectedDate}
              disabled     = {report?.id && !report.permissions.can_update_report}
              required     = {report?.id && report.permissions.must_set_expected_date}
              allowClear
            />
      }

      <Select
        name          = 'report_priority'
        label         = {i18n.t('report.priority')}
        search        = {true}
        searchUrl     = '/report_priorities/search'
        defaultValue  = {[report.priority]}
        format        = {{ content: 'name', value: 'id', details: 'duration_string' }}
        disabled      = {report?.id && !report.permissions.can_update_report}
        withEmpty     = {true}
        marginY       = 'M'
      />

      {reportType.urgent !== TYPE_VALUES.HIDDEN &&
        <Checkbox
          name         = 'urgent'
          label        = {<><FontAwesomeIcon icon="exclamation-circle" /> {i18n.t('report.urgent_question')}</>}
          defaultValue = {report.urgent}
          callback     = {setUrgentChecked}
          disabled     = {report?.id && !report.permissions.can_update_report}
          marginY      = 'M'
          required     = {reportType === TYPE_VALUES.MANDATORY}
        />
      }

      {(!report?.id || (report.permissions.can_update_report || report.permissions.can_update_description)) &&
        <Input
          name         = 'submit'
          type         = 'submit'
          defaultValue = {i18n.t('actions.save')}
        />
      }
    </form>
  )
}

export default ReportForm
