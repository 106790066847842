import React, { useEffect, useState } from 'react'

import FileUploader from '@form/FileUploader'

import IMaintenancePlan from '@interfaces/IMaintenancePlan.d'

interface PlanFormDocumentsProps {
  data?:    IMaintenancePlan
  setData?: React.Dispatch<React.SetStateAction<IMaintenancePlan>>
}

const PlanFormDocuments: React.FC<PlanFormDocumentsProps> = ({ data, setData }) => {

  const [documents, setDocuments] = useState(data.documents)

  useEffect(() => setData(initialData => ({...initialData, documents })), [documents])

  const addDocument    = file => setDocuments(documents => [...documents, file])
  const removeDocument = file => {
    const index = documents.findIndex(f => f.id === file.id)
    const documentsCopy = [...documents]
    documentsCopy.splice(index, 1)
    setDocuments(documentsCopy)
  }
  return(
    <>
      <FileUploader
        files           = {documents}
        objectType      = 'maintenance-plan'
        objectId        = {null}
        closeModalAfter = {false}
        grid            = {false}
        updateMethods   = {{ addDocument, removeDocument }}
        destroyable
      />
    </>
  )
}

export default PlanFormDocuments
