import React, { useEffect, useMemo, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Button     from '@components/Button'
import DatePicker from '@form/DatePicker'
import OrderTag   from '@components/OrderTag'
import Search     from '@components/Search'
import Select     from '@form/Select'
import SelectTree from '@form/SelectTree'
import Switch     from '@components/Switch'

import ReportList from './ReportColumn'

import { buildFilterString } from '@utils/filterString'

import * as Style from './style'

import { useGlobalContextState } from '@context/GlobalContext'

interface ReportIndexProps {
  new_reports,
  done_reports,
  ongoing_reports,
  filter_presets
}

const ReportIndex: React.FC<ReportIndexProps> = ({
  new_reports,
  done_reports,
  ongoing_reports,
  filter_presets
}) => {
  const {
    i18n,
    isDesktop,
    fetchApi,
    current_company,
    current_user,
    CONSTANTS,
  } = useGlobalContextState()

  const VIEW = {
    NEW:      'new',
    ONGOING:  'ongoing',
    TO_CHECK: 'done'
  }

  const [view,                   setView]                   = useState(filter_presets?.view || VIEW.NEW)
  const [showFilter,             setShowFilter]             = useState(false)
  const [searchValue,            setSearchValue]            = useState('')
  const [selectedResponsible,    setSelectedResponsible]    = useState(null)
  const [selectedRequester,      setSelectedRequester]      = useState(null)
  const [selectedCreator,        setSelectedCreator]        = useState(null)
  const [selectedDoer,           setSelectedDoer]           = useState(null)
  const [selectedUnit,           setSelectedUnit]           = useState(null)
  const [selectedExpertise,      setSelectedExpertise]      = useState(null)
  const [selectedAnomaly,        setSelectedAnomaly]        = useState(null)
  const [selectedAmenityType,    setSelectedAmenityType]    = useState(null)
  const [selectedAmenity,        setSelectedAmenity]        = useState(filter_presets?.amenity || null)
  const [selectedReportType,     setSelectedReportType]     = useState(null)
  const [selectedReportPriority, setSelectedReportPriority] = useState(null)
  const [selectedStatus,         setSelectedStatus]         = useState([])
  const [isLate,                 setIsLate]                 = useState([])
  const [deadline,               setDeadline]               = useState([])
  const [expectedDate,           setExpectedDate]           = useState([])
  const [withMaintenance,        setWithMaintenance]        = useState(filter_presets?.with_maintenance || null)
  const [order, setOrder] = useState({
    value:     current_user.preferences?.reports?.index?.order?.value,
    direction: current_user.preferences?.reports?.index?.order?.direction
  })
  const [filters, setFilters] = useState({
    search:           '',
    responsible_id:   null,
    requester_id:     null,
    created_by_id:    null,
    doer_id:          null,
    unit:             null,
    expertise:        null,
    anomaly:          null,
    amenity_type_id:  null,
    amenity_id:       filter_presets?.amenity?.id,
    report_type:      null,
    report_priority:  null,
    status:           null,
    late:             null,
    deadline:         null,
    expected_date:    null,
    order:            current_user.preferences?.reports?.index?.order?.value,
    direction:        current_user.preferences?.reports?.index?.order?.direction,
    initial_render:   true,
    with_maintenance: filter_presets?.with_maintenance?.value
  })

  const [filterUrl, setFilterUrl] = useState(buildFilterString(filters))

  useEffect(() => setFilters(filters => ({...filters, initial_render: false, search: searchValue }) ), [searchValue])
  useEffect(() => setFilters(filters => ({...filters, initial_render: false, responsible_id: selectedResponsible?.id }) ), [selectedResponsible])
  useEffect(() => setFilters(filters => ({...filters, initial_render: false, requester_id: selectedRequester?.id }) ), [selectedRequester])
  useEffect(() => setFilters(filters => ({...filters, initial_render: false, created_by_id: selectedCreator?.id }) ), [selectedCreator])
  useEffect(() => setFilters(filters => ({...filters, initial_render: false, doer_id: selectedDoer?.id }) ), [selectedDoer])
  useEffect(() => setFilters(filters => ({...filters, initial_render: false, unit: selectedUnit?.id }) ), [selectedUnit])
  useEffect(() => setFilters(filters => ({...filters, initial_render: false, expertise: selectedExpertise?.id }) ), [selectedExpertise])
  useEffect(() => setFilters(filters => ({...filters, initial_render: false, anomaly: selectedAnomaly?.id }) ), [selectedAnomaly])
  useEffect(() => setFilters(filters => ({...filters, initial_render: false, amenity_type_id: selectedAmenityType?.id }) ), [selectedAmenityType])
  useEffect(() => setFilters(filters => ({...filters, initial_render: false, amenity_id: selectedAmenity?.id }) ), [selectedAmenity])
  useEffect(() => setFilters(filters => ({...filters, initial_render: false, report_type: selectedReportType?.id }) ), [selectedReportType])
  useEffect(() => setFilters(filters => ({...filters, initial_render: false, report_priority: selectedReportPriority?.id }) ), [selectedReportPriority])
  useEffect(() => setFilters(filters => ({...filters, initial_render: false, status: selectedStatus.map(s => s && s.value).join(',') }) ), [selectedStatus])
  useEffect(() => setFilters(filters => ({...filters, initial_render: false, late: isLate?.value?.toString() }) ), [isLate])
  useEffect(() => setFilters(filters => ({...filters, initial_render: false, deadline: deadline?.filter(e => e).join(' - ') }) ), [deadline])
  useEffect(() => setFilters(filters => ({...filters, initial_render: false, expected_date: expectedDate?.filter(e => e).join(' - ') }) ), [expectedDate])
  useEffect(() => setFilters(filters => ({...filters, initial_render: false, order: order.value, direction: order.direction }) ), [order])
  useEffect(() => setFilters(filters => ({...filters, initial_render: false, with_maintenance: withMaintenance?.value }) ), [withMaintenance])
  useEffect(() => setFilterUrl(buildFilterString(filters)), [filters])

  useEffect (() => {
    fetchApi({
      url:    'users/update_preferences',
      method: 'PATCH',
      body:   {
        preferences: {
          reports: {
            index: {
              order: { value: order.value, direction: order.direction }
            }
          }
        }
      }
    })
  }, [order])


  const selectUnit = response => {
    // To avoid unnecessary trigger of useEffect fetch
    if (selectedUnit?.id === null && [undefined, null].includes(response)) { return }
    if (selectedUnit?.id !== response?.id) { setSelectedUnit(response) }
  }

  const userFilter = useMemo(() => {
    return current_company.reports_limited_to_expertise
      ? [{
        id:      'expertise',
        name:    selectedExpertise?.name,
        filters: { expertise: selectedExpertise?.id, role: 'manager,local_manager' }
      }]
      : [{
        id:      'role',
        name:    'Role',
        filters: { role: 'manager,local_manager' }
      }]
  }, [selectedExpertise])

  const statusOptions = useMemo(() =>
    CONSTANTS.REPORT.STATUSES.map(status => ({ value: status, text: i18n.t(`report.status.${status}`)}))
  , [])

  const switchOptions = useMemo(() => {
    const options = [
      { content: i18n.t('report.status.new'),     icon: <FontAwesomeIcon icon="star" />,       value: VIEW.NEW },
      { content: i18n.t('report.status.ongoing'), icon: <FontAwesomeIcon icon="stopwatch" />,  value: VIEW.ONGOING },
      { content: i18n.t('report.status.done'),    icon: <FontAwesomeIcon icon="user-check" />, value: VIEW.TO_CHECK },
    ]
    return options
  }, [])

  const reorderColumns = (orderState) => {
    setOrder({ value: orderState.value, direction: orderState.direction })
  }

  return (
    <Style.ReportListLayout
      isDesktop = {isDesktop}
      filters   = {filters}
    >
      <Style.HeaderStyles>
        <Style.HeaderContent>
          <Style.HeaderFiltersAndOrdering
            isDesktop = {isDesktop}
          >
            <Button
              icon   = {<FontAwesomeIcon icon="fa-solid fa-wand-magic-sparkles" />}
              border = 'var(--rep-primary)'
              click  = {() => setShowFilter(!showFilter)}
            >
              {i18n.t('actions.filter')}
            </Button>

            <OrderTag
              click        = {(orderState) => reorderColumns(orderState)}
              value        = 'deadline'
              activeValue  = {order.value}
              defaultOrder = {order}
            >
              {i18n.t('report.deadline')}
            </OrderTag>
            <OrderTag
              click        = {(orderState) => reorderColumns(orderState)}
              value        = 'created_at'
              activeValue  = {order.value}
              defaultOrder = {order}
            >
              {i18n.t('shared.created_at')}
            </OrderTag>
          </Style.HeaderFiltersAndOrdering>
          <Search callback={setSearchValue} />
        </Style.HeaderContent>
        {showFilter &&
          <Style.FilterList
            toggled   = {showFilter}
            isDesktop = {isDesktop}
          >
            <DatePicker
              label    = {i18n.t('report.deadline')}
              mode     = 'range'
              name     = 'deadline'
              showTime = {false}
              callback = {setDeadline}
              required
              allowClear
            />
            <DatePicker
              label    = {i18n.t('report.expected_date')}
              mode     = 'range'
              name     = 'expected_date'
              showTime = {false}
              callback = {setExpectedDate}
              required
              allowClear
            />
            <div></div>
            <Select
              name             = 'responsible'
              label            = {i18n.t('shared.responsible')}
              withEmpty        = {true}
              search           = {true}
              searchUrl        = '/users/search'
              filters          = {userFilter}
              defaultFilter    = 'expertise'
              defaultValue     = {[selectedResponsible]}
              callback         = {(response) => setSelectedResponsible(response?.object)}
              emptyCallback    = {(response) => setSelectedResponsible(response?.object)}
              format           = {{ content: 'name', details: 'role', value: 'id' }}
              marginY          = 'S'
              detailsLocaleKey = 'shared'
            />
            <Select
              name             = 'requester'
              label            = {i18n.t('shared.requested_by')}
              withEmpty        = {true}
              search           = {true}
              searchUrl        = '/users/search'
              defaultValue     = {[selectedRequester]}
              format           = {{ content: 'name', details: 'role', value: 'id' }}
              callback         = {(response) => setSelectedRequester(response?.object)}
              emptyCallback    = {(response) => setSelectedRequester(response?.object)}
              marginY          = 'S'
              detailsLocaleKey = 'shared'
            />
            <Select
              name             = 'created_by'
              label            = {i18n.t('shared.created_by')}
              withEmpty        = {true}
              search           = {true}
              searchUrl        = '/users/search'
              defaultValue     = {[selectedCreator]}
              format           = {{ content: 'name', details: 'role', value: 'id' }}
              callback         = {(response) => setSelectedCreator(response?.object)}
              emptyCallback    = {(response) => setSelectedCreator(response?.object)}
              marginY          = 'S'
              detailsLocaleKey = 'shared'
            />
            <Select
              name             = 'doer_id'
              label            = {i18n.t('report.performer')}
              withEmpty        = {true}
              search           = {true}
              searchUrl        = '/users/search'
              defaultValue     = {[selectedDoer]}
              format           = {{ content: 'name', details: 'role', value: 'id' }}
              callback         = {(response) => setSelectedDoer(response?.object)}
              emptyCallback    = {(response) => setSelectedDoer(response?.object)}
              marginY          = 'S'
              detailsLocaleKey = 'shared'
            />
            <Select
              name          = 'expertise'
              label         = {i18n.t('expertise.expertise')}
              withEmpty     = {true}
              search        = {true}
              searchUrl     = '/expertises/search'
              defaultValue  = {[selectedExpertise]}
              callback      = {(response) => setSelectedExpertise(response?.object)}
              emptyCallback = {(response) => setSelectedExpertise(response?.object)}
              format        = {{ content: 'name', value: 'id' }}
              marginY       = 'S'
            />
            <Select
              name          = 'anomaly'
              label         = {i18n.t('anomaly.anomaly')}
              withEmpty     = {true}
              search        = {true}
              searchUrl     = '/anomalies/search'
              defaultValue  = {[selectedAnomaly]}
              callback      = {(response) => setSelectedAnomaly(response?.object)}
              emptyCallback = {(response) => setSelectedAnomaly(response?.object)}
              format        = {{ content: 'name', value: 'id' }}
              marginY       = 'S'
            />
            <Select
              name          = 'amenity_type_id'
              label         = {i18n.t('amenity.amenity_type_long')}
              withEmpty     = {true}
              search        = {true}
              searchUrl     = '/amenity_types/search'
              defaultValue  = {[selectedAmenityType]}
              callback      = {(response) => setSelectedAmenityType(response?.object)}
              emptyCallback = {() => setSelectedAmenityType({ id: null, name: null })}
              format        = {{ content: 'name', value: 'id' }}
              marginY       = 'S'
            />
            <Select
              name          = 'report_type'
              label         = {i18n.t('report.report_type')}
              withEmpty     = {true}
              search        = {true}
              searchUrl     = '/report_types/search'
              defaultValue  = {[selectedReportType]}
              callback      = {(response) => setSelectedReportType(response?.object)}
              emptyCallback = {(response) => setSelectedReportType(response?.object)}
              format        = {{ content: 'name', value: 'id' }}
              marginY       = 'S'
            />
            <Select
              name          = 'report_priority'
              label         = {i18n.t('report_priority.report_priority')}
              withEmpty     = {true}
              search        = {true}
              searchUrl     = '/report_priorities/search'
              defaultValue  = {[selectedReportPriority]}
              callback      = {(response) => setSelectedReportPriority(response?.object)}
              emptyCallback = {(response) => setSelectedReportPriority(response?.object)}
              format        = {{ content: 'name', value: 'id' }}
              marginY       = 'S'
            />
            <Select
              name          = 'status'
              label         = {i18n.t('report.report_status')}
              withEmpty     = {true}
              options       = {statusOptions}
              defaultValue  = {selectedStatus}
              callback      = {setSelectedStatus}
              emptyCallback = {() => setSelectedStatus([])}
              format        = {{ content: 'text', value: 'value' }}
              multiselect
              marginY       = 'S'
            />
            <Select
              name          = 'late'
              label         = {i18n.t('shared.late')}
              withEmpty     = {true}
              options       = {[{ value: true, text: i18n.t('shared._yes') }, { value: false, text: i18n.t('shared._no') }]}
              defaultValue  = {[isLate]}
              callback      = {(response) => setIsLate(response?.object)}
              emptyCallback = {(response) => setIsLate(response?.object)}
              format        = {{ content: 'text', value: 'value' }}
              marginY       = 'S'
            />
            <Select
              name          = 'with_maintenance'
              label         = {i18n.t('maintenance.actions.include_maintenances')}
              options       = {[{ value: true, text: i18n.t('shared._yes') }, { value: false, text: i18n.t('shared._no') }]}
              defaultValue  = {[withMaintenance]}
              callback      = {(response) => setWithMaintenance(response?.object)}
              emptyCallback = {(response) => setWithMaintenance(response?.object)}
              format        = {{ content: 'text', value: 'value' }}
              marginY       = 'S'
            />
            <Select
              name          = 'amenity_id'
              label         = {i18n.t('amenity.amenity')}
              withEmpty     = {true}
              search        = {true}
              searchUrl     = '/amenities/rep_search'
              defaultValue  = {[selectedAmenity]}
              callback      = {(response) => setSelectedAmenity(response?.object)}
              emptyCallback = {(response) => setSelectedAmenity(response?.object)}
              format        = {{ content: 'name', value: 'id' }}
              marginY       = 'S'
              filters       = {[
                {
                  id:      'all',
                  name:    'All',
                  filters: {
                    root_unit_id:    `${selectedUnit?.id ? selectedUnit.id : ''}`,
                    amenity_type_id: `${selectedAmenityType?.id ? selectedAmenityType.id : ''}`
                  }
                }
              ]}
              defaultFilter  = 'all'
            />
            <SelectTree
              name      = 'unit'
              label     = {i18n.t('unit.unit')}
              selected  = {selectedUnit || { id: null, name: null, path: '' }}
              callback  = {selectUnit}
              withEmpty = {true}
              searchUrl = '/units/rep_search'
              emptyCallback = {() => setSelectedUnit({ id: null, name: null, path: '' })}
              filters   = {[{
                id:      'root',
                name:    'Parent',
                filters: { roots: 'true' }
              }]}
              format         = {{ content: 'name', value: 'id' }}
              disabled       = {false}
              marginY        = 'S'
              subFilterName  = 'for_unit'
              subElementName = {i18n.t('unit.subunit')}
            />
          </Style.FilterList>
        }
      </Style.HeaderStyles>
      {!isDesktop &&
        <Switch
          name     = 'report-view'
          options  = {switchOptions}
          selected = {view}
          callback = {setView}
          marginY  = 'S'
        />
      }
      {(isDesktop || view === VIEW.NEW) &&
        <Style.Column>
          <ReportList listType={VIEW.NEW} filterUrl={filterUrl} reports={new_reports} />
        </Style.Column>
      }
      {(isDesktop || view === VIEW.ONGOING) &&
        <Style.Column>
          <ReportList listType={VIEW.ONGOING} filterUrl={filterUrl} reports={ongoing_reports} />
        </Style.Column>
      }
      {(isDesktop || view === VIEW.TO_CHECK) &&
        <Style.Column>
          <ReportList listType={VIEW.TO_CHECK} filterUrl={filterUrl} reports={done_reports} />
        </Style.Column>
      }
    </Style.ReportListLayout>
  )
}

export default ReportIndex
