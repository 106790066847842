import React, { useMemo, useReducer }  from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Button           from '@components/Button'
import Card             from '@components/Card'
import Column           from '@components/Column'
import ExpectedDateForm from '@components/Report/ExpectedDateForm'
import { Filter }       from '@components/Column/Filter'
import Task             from '@components/Task'
import TaskForm         from '@components/Task/Form'

import * as Style from './style'

import { useGlobalContextState } from '@context/GlobalContext'
import { useReportContextState } from '@context/ReportContext'

import { REPORT_ACTIONS, TASK_ACTIONS, taskReducer } from '@reducers/index'

interface IReportTasks {
  maxHeight?: string
  grow?:      boolean
  height?:    string
}

const ReportsTasks: React.FC<IReportTasks> = ({
  maxHeight = '100%',
  grow      = true,
  height    = '100%',
}) => {

  const {
    initAPI,
    showModal,
    i18n,
    CONSTANTS,
    fetchApi,
    closeModal
  } = useGlobalContextState()

  const {
    report,
    setReport,
    updateMethods,
    reportDispatch
  } = useReportContextState()

  const { TASK } = CONSTANTS

  const [_state, dispatch] = useReducer(taskReducer, { report, setReport, fetchApi, closeModal, updateMethods })

  const API = initAPI({ reportId: report.id })

  const addReportExpectedDate = data => reportDispatch({
    type:      REPORT_ACTIONS.UPDATE,
    data,
    callbacks: [
      () => API.fetchMinimalReport().then(updateMethods.minimalReport),
      closeModal
    ]
  })
  const createTask = data => dispatch({
    type:      TASK_ACTIONS.CREATE, data, callbacks: [
      () => API.fetchMinimalReport().then(updateMethods.minimalReport),
      () => API.fetchConversations().then(updateMethods.conversations),
    ]
  })

  const addForm = data => dispatch({
    type: TASK_ACTIONS.CREATE_FORM, data
  })

  const addTaskForm = ()   => {
    if (!report.expected_date && report.permissions.must_set_expected_date) {
      showModal({
        title:   i18n.t('todo.actions.add_task'),
        content: <ExpectedDateForm
          report   = {report}
          callback = {addReportExpectedDate}
        />
      })
    } else {
      showModal({
        title:   i18n.t('todo.actions.add_task'),
        content: <TaskForm
          report     = {report}
          createTask = {createTask}
          addForm    = {addForm}
        />
      })
    }
  }

  const headerActions = useMemo(() =>
    [report.permissions.can_create_task &&
      <Button
        key   = 'create-task'
        icon  = {<FontAwesomeIcon icon="plus" />}
        color = 'var(--rep-primary)'
        hover = 'var(--rep-primary-light)'
        click = {addTaskForm}
      >
        {i18n.t('actions.add')}
      </Button>], [report.permissions])

  const emptyCard = useMemo(() =>
    <Card border='var(--rep-neutral-primary-light)' togglable={false}>
      <Style.EmptyColumn>
        <p><FontAwesomeIcon icon="mug-hot" /></p>
        {i18n.t('todo.page.no_todos')}
      </Style.EmptyColumn>
    </Card>, [])

  const emptyFilters = useMemo(() =>
    <Card border='var(--rep-neutral-primary-light)' togglable={false}>
      <Style.EmptyColumn>
        <p><FontAwesomeIcon icon="ghost" /></p>
        {i18n.t('todo.page.no_filtered_todos')}
      </Style.EmptyColumn>
    </Card>, [])

  const filters = useMemo(() => new Filter({
    references: {
      status: TASK.STATUS
    },
    filters: [
      {
        id:        TASK.STATUS.NEW,
        name:      i18n.t('todo.status.new'),
        icon:      <FontAwesomeIcon icon={['far', 'paper-plane']} />,
        reference: 'status'
      },
      {
        id:        TASK.STATUS.ASSIGNED,
        name:      i18n.t('todo.status.assigned'),
        icon:      <FontAwesomeIcon icon={['far', 'user']} />,
        reference: 'status'
      },
      {
        id:        TASK.STATUS.DONE,
        name:      i18n.t('todo.status.done'),
        icon:      <FontAwesomeIcon icon={['far', 'eye']} />,
        reference: 'status'
      },
      {
        id:        TASK.STATUS.CLOSED,
        name:      i18n.t('todo.status.closed'),
        icon:      <FontAwesomeIcon icon='check' />,
        reference: 'status'
      },
    ],
    totalFilteredCount: report.total_filtered_tasks,
    perPage:            20,
    totalPages:         report.total_tasks_pages,
    fetch:              API.fetchTasks,
    pageCallback:       data => updateMethods.moreTasks(data),
    callback:           updateMethods.tasks
  }), [])

  return(
    <Column
      togglable
      open
      paginated
      withFilters
      maxHeight        = {maxHeight}
      height           = {height}
      grow             = {grow}
      title            = {i18n.t('todo.todos')}
      totalCount       = {report.total_filtered_tasks}
      currentCount     = {report.tasks.length}
      totalFiltered    = {report.total_filtered_tasks}
      totalPages       = {report.total_tasks_pages}
      headerActions    = {headerActions}
      showEmptyContent = {!report.total_tasks}
      emptyContent     = {emptyCard}
      showEmptyFilters = {!!report.total_tasks && !report.total_filtered_tasks}
      emptyFilters     = {emptyFilters}
      filters          = {filters}
    >
      {report.tasks.map(task =>
        <Task
          key            = {task.id}
          task           = {task}
          report         = {report}
          updateMethods  = {updateMethods}
        />
      )}
    </Column>
  )
}

export default ReportsTasks
