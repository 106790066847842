import React, { useReducer, useState } from 'react'

import Callout    from '@components/Callout'
import Checkbox   from '@form/Checkbox'
import DatePicker from '@form/DatePicker'
import Input      from '@form/Input'
import TaskBox    from '@components/Task/TaskBox'
import Textarea   from '@components/Form/Textarea'

import { useGlobalContextState }       from '@context/GlobalContext'
import { useMaintenancesContextState } from '@context/MaintenancesContext'

import { MAINTENANCE_ACTIONS, maintenanceReducer } from '@reducers/maintenanceReducer'

import * as FormStyle from '@form/FormStyles'

import { ISubLine } from '@interfaces/ICalendar.d'

interface PlanifyFormProps {
  maintenance:    ISubLine
  date:           string
  from:           string
  fetchMainLine:  () => void
  fetchSubLine:   () => void
  cancelPrevious: boolean
}

const PlanifyForm: React.FC<PlanifyFormProps> = ({
  maintenance,
  date,
  from,
  fetchMainLine,
  fetchSubLine,
  cancelPrevious = false
}) => {

  const { i18n, fetchApi, CONSTANTS, closeModal } = useGlobalContextState()
  const { filters, MAINTENANCE_CONSTANTS }        = useMaintenancesContextState()

  const { VIEWS }    = MAINTENANCE_CONSTANTS
  const { EXTERNAL } = CONSTANTS.RECURRENCY.ASSIGNATIONS

  const [maintenanceData, setMaintenanceData] = useState(maintenance?.data)
  const [cancelCheck, setCancelCheck]         = useState(false)

  const [_state, dispatch] = useReducer(maintenanceReducer, {
    fetchApi,
    filters,
    closeModal,
    VIEWS,
  })

  const removeUser = (task, user) => {
    const taskData = {...task, users: task.users.filter(u => u.id !== user.id)}
    const tasks = maintenanceData.tasks.map(t => t.id === taskData.id ? taskData : t)
    setMaintenanceData(data => ({...data, tasks: tasks }))
  }

  const addUser = (task, user) => {
    if (task.users.map(u => u.id).includes(user.object.id)) return

    let taskData
    if (task.assignation === EXTERNAL) {
      taskData = {...task, users: [user.object] }
    } else {
      taskData = {...task, users: [...task.users, user.object] }
    }
    let tasks = maintenanceData.tasks
    tasks = tasks.map(t => t.id === taskData.id ? taskData : t)
    setMaintenanceData(data => ({...data, tasks: tasks }))
  }

  const planifyMaintenance = event => {
    event.preventDefault()
    const formData = new FormData(event.currentTarget)
    const data     = Object.fromEntries(formData)

    dispatch({
      type:      MAINTENANCE_ACTIONS.PLANIFY,
      maintenance,
      data,
      callbacks: [
        () => fetchSubLine(),
        () => fetchMainLine()
      ],
    })
  }

  return (
    <form onSubmit={planifyMaintenance}>
      <Input
        type         = "hidden"
        name         = "original_date"
        defaultValue = {date}
      />
      <Input
        type         = "hidden"
        name         = "maintenance_id"
        defaultValue = {maintenance?.data?.id}
      />
      <Input
        type         = "hidden"
        name         = "exclusion"
        defaultValue = {from}
      />

      <DatePicker
        label = {i18n.t('report.expected_date')}
        name  = 'date'
        date  = {date}
        showTime
        required
      />

      <FormStyle.Label>
        {i18n.t('maintenance.default_assignment')}
      </FormStyle.Label>

      {maintenanceData.tasks.length
        ? maintenanceData.tasks.map((task, key) =>
          <TaskBox
            key          = {`${task.name}-${key}`}
            task         = {task}
            addUser      = {user => addUser(task, user)}
            removeUser   = {(task, user) => removeUser(task, user)}
          />)
        : <Callout
          marginY    = 'M'
          background = 'var(--rep-warning-light)'
          color      = 'var(--rep-warning)'
          border     = 'var(--rep-warning)'
        >
          {i18n.t('maintenance.no_tasks')}
        </Callout>
      }

      <Checkbox
        name         = 'notify'
        label        = {i18n.t('maintenance.form.notify_associated_generation')}
        defaultValue = {true}
        marginY      = 'M'
      />
      {cancelPrevious &&
        <>
          <Checkbox
            name         = 'cancel_previous'
            label        = {i18n.t('maintenance.form.cancel_previous_tickets')}
            callback     = {check => setCancelCheck(check.isCheck)}
            defaultValue = {cancelCheck}
            marginY      = 'M'
          />
          {cancelCheck &&
          <Textarea
            label = {i18n.t('maintenance.form.cancel_reason')}
            name  = 'cancel_comment'
            required
          />
          }
        </>
      }
      <Input
        name         = "planify-report"
        type         = 'submit'
        defaultValue = {i18n.t('maintenance.actions.plan_maintenance')}
      />
    </form>
  )
}

export default PlanifyForm
