import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon }                      from '@fortawesome/react-fontawesome'
import toast                                    from 'react-hot-toast'

import Button          from '@components/Button'
import Callout         from '@components/Callout'
import QuestionForm    from '@components/FormQuestion/Form'
import Switch          from '@components/Switch'
import TaskBox         from '@components/Task/TaskBox'

import Input           from '@form/Input'
import MultiselectItem from '@form/Select/MultiselectItem'
import Select          from '@form/Select'
import Textarea        from '@form/Textarea'

import * as FormStyle from '@form/FormStyles'

import { useGlobalContextState } from '@context/GlobalContext'

import IMaintenancePlan from '@interfaces/IMaintenancePlan.d'

interface PlanFormTasksProps {
  data?:    IMaintenancePlan
  setData?: React.Dispatch<React.SetStateAction<IMaintenancePlan>>
}

const PlanFormTasks: React.FC<PlanFormTasksProps> = ({ data, setData }) => {

  const { i18n, CONSTANTS, current_company } = useGlobalContextState()

  const { INTERNAL, EXTERNAL, UNASSIGNED } = CONSTANTS.RECURRENCY.ASSIGNATIONS

  const [tasks, setTasks] = useState(data.tasks)

  const uniqueId = useCallback(() => window.btoa(Math.random().toString()).substring(10, 15), [tasks])

  const [identifier,  setIdentifier]  = useState(uniqueId())
  const [name,        setName]        = useState('')
  const [description, setDescription] = useState('')
  const [hours,       setHours]       = useState(null)
  const [minutes,     setMinutes]     = useState(null)
  const [periodicity, setPeriodicity] = useState(1)
  const [offset,      setOffset]      = useState(0)
  const [assignation, setAssignation] = useState(null)
  const [users,       setUsers]       = useState([])
  const [provider,    setProvider]    = useState(null)

  const [showQuestionForm, setShowQuestionForm] = useState(false)
  const [showTaskOptions,  setShowTaskOptions]  = useState(false)
  const [formsTemplates,   setFormsTemplates]   = useState([])
  const [formsCreated,     setFormsCreated]     = useState([])

  useEffect(() => { setData(data => ({...data, tasks })) }, [tasks])

  const textAreaRef = useRef(null)

  const changeAssignation = assignation => {
    setProvider(null)
    setUsers([])
    setAssignation(assignation)
  }
  const addUser = user => {
    if (users.map(u => u.id).includes(user.object.id)) return
    setUsers(usersSelected => [...usersSelected, user.object])
  }
  const removeUser = user => setUsers(users.filter(u => u.id !== user.id))

  const addFormTemplates = forms => {
    setFormsTemplates(formsTemplates => [...formsTemplates, ...forms.map(f => f.object)])
  }
  const addForm = form => {
    setFormsCreated([...formsCreated, form])
    setShowQuestionForm(false)
  }
  const removeFormTemplate = form => setFormsTemplates(formsTemplates.filter(f => f.id !== form.id))
  const removeFormCreated  = form => setFormsCreated(formsCreated.filter(f => f.tempId !== form.tempId))

  const submitTask = () => {
    if (!name) {
      toast(i18n.t('maintenance.error.enter_task_name'), { icon: '⚠️' })
      return
    }

    const task = {
      id: identifier,
      name,
      description,
      hours,
      minutes,
      assignation,
      provider,
      users,
      formsTemplates,
      formsCreated,
      periodicity,
      offset
    }

    task.id = task?.id || uniqueId()

    if (tasks.find(t => t.id === task.id)) {
      setTasks(tasks.map(t => t.id === task.id ? task : t))
    } else {
      setTasks(tasks => [...tasks, task])
    }

    setIdentifier(null)
    setName('')
    setDescription('')
    textAreaRef.current.value = ''
    setHours('')
    setMinutes('')
    setAssignation(UNASSIGNED)
    setUsers([])
    setProvider(null)
    setFormsTemplates([])
    setFormsCreated([])
    setPeriodicity(1)
    setOffset(0)
    setShowTaskOptions(false)
  }

  const editTask = task => {
    setShowTaskOptions(true)
    setProvider(task.provider)
    setIdentifier(task?.id || task?.identifier)
    setName(task.name)
    setDescription(task.description)
    textAreaRef.current.value = task.description
    setHours(task.hours)
    setMinutes(task.minutes)
    setFormsTemplates(task.formsTemplates || [])
    setFormsCreated(task.formsCreated || [])
    setPeriodicity(task.periodicity)
    setOffset(task.offset)
    setAssignation(task.assignation)
    setUsers(task.users)
  }

  const removeTask = (key) => {
    const tasksArray = [...tasks]
    tasksArray.splice(key, 1)
    setTasks(tasksArray)
  }

  return (
    <>
      <FormStyle.Header>
        <FontAwesomeIcon icon="circle-info" />
        {i18n.t('maintenance.associated_tasks')}
      </FormStyle.Header>

      {tasks.length
        ? tasks.map((task, key) => <TaskBox key={`${task.name}-${key}`} task={task} editAction={editTask} removeAction={() => removeTask(key)} />)
        : <Callout
          marginY    = 'M'
          background = 'var(--rep-warning-light)'
          color      = 'var(--rep-warning)'
          border     = 'var(--rep-warning)'
        >
          {i18n.t('maintenance.no_tasks')}
        </Callout>
      }

      <FormStyle.Header>
        <FontAwesomeIcon icon="list-check" />
        {i18n.t('maintenance.new_task')}
      </FormStyle.Header>

      {/* <FormStyle.Label>Description</FormStyle.Label> */}
      <Input
        name         = "task-name"
        type         = "text"
        placeholder  = {i18n.t('shared.name')}
        defaultValue = {name}
        change       = {e => setName(e.currentTarget.value)}
        marginY      = 'S'
        // required
      />
      <Textarea
        name         = "task-description"
        forwardRef   = {textAreaRef}
        placeholder  = {i18n.t('shared.description')}
        change       = {e => setDescription(e.currentTarget.value)}
        defaultValue = {description}
        marginY      = 'S'
      />

      <Button
        icon    = {<FontAwesomeIcon icon={showTaskOptions ? 'angle-up' : 'angle-down'} />}
        click   = {() => setShowTaskOptions(!showTaskOptions)}
        marginY = 'S'
        color   = 'var(--rep-primary)'
        border  = {showTaskOptions ? 'var(--rep-primary)' : ''}
      >
        {i18n.t('shared.more_options')}
      </Button>
      {showTaskOptions &&
        <>
          {/* <FormStyle.BorderedBox> */}
          {current_company.permissions.see_estimated_time &&
            <>
              {/* <FormStyle.Header>
                  <FontAwesomeIcon icon="clock" />
                  {i18n.t('todo.expected_duration')}
                </FormStyle.Header> */}
              <FormStyle.Label>{i18n.t('todo.expected_duration')}</FormStyle.Label>
              <FormStyle.Group>
                <Input
                  name         = 'hours'
                  label        = {i18n.t('shared.hours')}
                  type         = 'number'
                  defaultValue = {hours}
                  change       = {e => setHours(e.currentTarget.value)}
                />
                <Input
                  name         = 'minutes'
                  label        = {i18n.t('shared.minutes')}
                  type         = 'number'
                  min          = {0}
                  max          = {59}
                  maxLength    = {2}
                  defaultValue = {minutes}
                  change       = {e => setMinutes(e.currentTarget.value)}
                />
              </FormStyle.Group>
            </>
          }

          <FormStyle.Header marginY='M'>
            <FontAwesomeIcon icon='user-gear' />
            {i18n.t('todo.assignment')}
          </FormStyle.Header>
          <div>
            <Switch
              name    = 'assignation'
              options = {[
                { content: i18n.t('todo.internal'), value: INTERNAL },
                { content: i18n.t('service_provider.service_provider'), value: EXTERNAL },
              ]}
              selected = {assignation}
              callback = {changeAssignation}
              marginY  = 'S'
            />
          </div>


          {assignation === INTERNAL &&
            <Select
              label        = {i18n.t('user.users')}
              name         = 'user-selected'
              searchUrl    = '/users/search'
              defaultValue = {[users]}
              filters      = {[
                {
                  id:      'technicians',
                  name:    i18n.t('user.role.technicians'),
                  filters: { role: ['technician', 'technician_manager'] }
                },
                {
                  id:      'managers',
                  name:    i18n.t('user.role.managers'),
                  filters: { role: ['manager', 'local_manager'] }
                },
                {
                  id:      'employees',
                  name:    i18n.t('user.role.employees'),
                  filters: { role: ['employee', 'employee_manager'] }
                },
                {
                  id:      'all',
                  name:    i18n.t('shared.all'),
                  filters: {}
                }
              ]}
              defaultFilter    = 'technicians'
              placeholder      = {i18n.t('user.your_users')}
              format           = {{ content: 'name', value: 'id', details: 'role' }}
              detailsLocaleKey = 'shared'
              marginY          = 'M'
              callback         = {addUser}
              search
            />
          }

          {assignation === EXTERNAL &&
            <>
              <Select
                name         = 'service-provider-selected'
                searchUrl    = '/service_providers/search'
                defaultValue = {[provider]}
                label        = {i18n.t('service_provider.service_providers')}
                placeholder  = {i18n.t('service_provider.your_service_providers')}
                format       = {{ content: 'name', value: 'id', details: 'expertises' }}
                marginY      = 'M'
                callback     = {sp => setProvider(sp.object)}
                search
              />
              {provider &&
                <Select
                  name          = 'user-selected'
                  label         = {provider.name}
                  defaultValue  = {[users]}
                  search
                  searchUrl     = {`/service_providers/search_users?provider=${provider.id}`}
                  placeholder   = {i18n.t('todo.provider_users', { provider: provider.name })}
                  format        = {{ content: 'name', value: 'id', details: 'role' }}
                  marginY       = 'M'
                  callback      = {user => setUsers([user.object])}
                  required
                />
              }
            </>
          }

          {!!users.length &&
            users.map(user =>
              <MultiselectItem
                key          = {user.id}
                name         = {user.name}
                icon         = {<FontAwesomeIcon icon={user.service_company ? 'user-tie' : 'user-gear'} />}
                confirmText  = {i18n.t('actions.confirm_delete')}
                removeAction = {() => removeUser(user)}
              />
            )
          }

          <FormStyle.Label>{i18n.t('maintenance.planification')}</FormStyle.Label>
          <FormStyle.Group>
            <FormStyle.TextBlock>{i18n.t('maintenance.plan_task')}</FormStyle.TextBlock>
            <Input
              name         = 'offset'
              type         = 'number'
              max          = {6}
              defaultValue = {offset}
              hint         = {i18n.t('maintenance.days_before_deadline')}
              tooltip      = {i18n.t('maintenance.tooltip.task_offset')}
              change       = {e => setOffset(Number.parseInt(e.currentTarget.value, 10))}
            />
            <FormStyle.TextBlock>{i18n.t('maintenance.actions.add_task_frequency')}</FormStyle.TextBlock>
            <Input
              name         = 'periodicity'
              type         = 'number'
              defaultValue = {periodicity}
              hint         = {i18n.t('maintenance.form.iterations').toLowerCase()}
              tooltip      = {i18n.t('maintenance.tooltip.task_periodicity')}
              change       = {e => setPeriodicity(Number.parseInt(e.currentTarget.value, 10))}
            />
          </FormStyle.Group>

          {current_company.permissions.can_see_forms &&
            <>
              <FormStyle.Label>{i18n.t('form.forms')}</FormStyle.Label>
              <Select
                name         = 'forms'
                searchUrl    = '/forms/search'
                defaultValue = {[]}
                callback     = {addFormTemplates}
                format       = {{ content: 'name', value: 'id', details: '' }}
                multiselect  = {true}
                placeholder  = {i18n.t('form.your_forms')}
                marginY      = 'S'
                search
                cleanAfterSelect
              />

              {formsTemplates.map(form =>
                <MultiselectItem
                  key          = {form.id}
                  name         = {form.name}
                  icon         = {<FontAwesomeIcon icon="file-lines" />}
                  confirmText  = {i18n.t('actions.confirm_delete')}
                  removeAction = {() => removeFormTemplate(form)}
                />
              )}

              {formsCreated.map(form =>
                <MultiselectItem
                  key          = {form.tempId}
                  name         = {form.name}
                  icon         = {<FontAwesomeIcon icon="file-circle-plus" />}
                  confirmText  = {i18n.t('actions.confirm_delete')}
                  removeAction = {() => removeFormCreated(form)}
                />
              )}

              <Button
                icon    = {<FontAwesomeIcon icon={showQuestionForm ? 'angle-down' : 'plus'} />}
                click   = {() => setShowQuestionForm(!showQuestionForm)}
                marginY = 'S'
                color   = 'var(--rep-success)'
                border  = {showQuestionForm ? '' : 'var(--rep-success)'}
              >
                {i18n.t('form.actions.create')}
              </Button>
              {showQuestionForm && <QuestionForm addForm={addForm} />}
            </>
          }
        </>
      }

      <Button
        click      = {submitTask}
        color      = 'var(--rep-success)'
        border     = 'var(--rep-success)'
        background = 'var(--rep-success-light)'
        icon       = {<FontAwesomeIcon icon="plus" />}
        align      = 'center'
        marginY    ='M'
        fullWidth
      >
        {identifier
          ? i18n.t('todo.actions.save_task')
          : i18n.t('todo.actions.add_task')
        }
      </Button>
    </>
  )
}

export default PlanFormTasks
