import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import toast from 'react-hot-toast'

const CREATE  = 'DOCUMENT_CREATE'
const UPDATE  = 'DOCUMENT_UPDATE'
const DESTROY = 'DOCUMENT_DESTROY'

export const DOCUMENT_ACTIONS = {
  CREATE:  CREATE,
  UPDATE:  UPDATE,
  DESTROY: DESTROY
}

export function documentReducer(state, action) {

  const { file, fetchApi, updateMethods, closeModal } = state

  switch (action.type) {
    case CREATE:
      fetchApi({
        url:         `/documents/rep_attach?type=${action.objectType}&${action.objectId ? `oid=${action.objectId}` : ''}`,
        method:      'POST',
        contentType: null,
        credentials: 'same-origin',
        body:        action.file,
        withLoading: true,
        stringify:   false,
        callback:    data => updateMethods.addDocument(data.response),
        followUps:   [
          () => action.closeModal && closeModal()
        ]
      })
      return state

    case UPDATE:
      fetchApi({
        url:       `/documents/${file.id}/rep_update.json`,
        method:    'PATCH',
        body:      action.data,
        callback:  data => updateMethods.document(data.response),
        followUps: [
          () => action.closeModal && closeModal()
        ]
      })
      return state

    case DESTROY:
      fetchApi({
        url:       `/documents/${file.id}/rep_delete.json`,
        method:    'DELETE',
        callback:  data => updateMethods.removeDocument(data.response),
        followUps: [
          () => action.closeModal && closeModal()
        ]
      })
      return state

    default:
      if (window.env !== 'production') toast.error('Reducer action not defined', { icon: <FontAwesomeIcon icon="face-tired" />, iconTheme: { primary: 'var(--rep-danger)', secondary: 'var(--rep-danger-light)' } })
      return state

  }
}
