import React, {
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { I18n }            from 'i18n-js'
import toast               from 'react-hot-toast'


import CONSTANTS    from '../config/constants.json'
import translations from '../config/translations.json'

import { ICompany, IUser }   from '../interfaces'
import { GlobalContextType } from './types.d'

import fetchApiWithToken, { fetchProps }  from '../utils/fetchApi'


declare global {
  interface Window {
    env: any;
  }
}

// create contexts
const GlobalContextState = createContext<GlobalContextType | null>(null)

// context consumer hook
const useGlobalContextState = () => {
  // get the context
  const context = useContext(GlobalContextState)

  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error('useGlobalContextState was used outside of its Provider')
  }
  return context
}


interface GlobalContextProviderProps {
  children:        React.ReactNode,
  current_user:    IUser,
  current_company: ICompany,
  token:           string,
  isDesktop:       boolean,
  locale:          string
}

const GlobalContextProvider: React.FC<GlobalContextProviderProps> = ({
  children,
  current_user,
  current_company,
  token,
  isDesktop,
}) => {

  const [showCommandBar, setShowCommandBar] = useState(false)
  const [commandBarOptions, setCommandBarOptions] = useState({})

  const [modalDisplay, toggleModal]     = useState(false)
  const [modalContent, setModalContent] = useState({ content: null, title: ''})

  const [alertDisplay, toggleAlert]     = useState(false)
  const [alertContent, setAlertContent] = useState(null)
  const [alertActions, setAlertActions] = useState([])

  const showModal = ({ content: content, title: title }) => {
    setModalContent({ content, title })
    toggleModal(true)
  }

  const closeModal = () => {
    setModalContent({ content: null, title: '' })
    toggleModal(false)
  }

  const showAlert = (content, actions) => {
    setAlertContent(content)
    setAlertActions(actions)
    toggleAlert(true)
  }
  const closeAlert = () => {
    setAlertContent(null)
    setAlertActions([])
    toggleAlert(false)
  }

  const [displayInfoWindow, setDisplayInfoWindow] = useState(false)
  const [infoResource, setInfoResource]           = useState({ type: null, id: null })
  const [infoHistory, setInfoHistory]             = useState([])

  // Object is { type: type, id: id }
  const openInfoWindow = (object, back = false) => {
    setInfoResource(object)
    !back && setInfoHistory([...infoHistory, object])
    setDisplayInfoWindow(true)
  }

  const closeInfoWindow = () => {
    setDisplayInfoWindow(false)
    setInfoResource(null)
    setInfoHistory([])
  }

  const i18n = new I18n()
  i18n.store(translations)
  i18n.defaultLocale  = 'fr'
  i18n.locale         = current_user?.preferred_locale || i18n.defaultLocale
  i18n.enableFallback = true

  if (window.env !== 'production') {
    i18n.missingTranslation.register('toast', (i18n, scope) => {
      toast.error(`Translation missing: ${scope} in ${new Intl.DisplayNames(['en'], {type: 'language'}).of(i18n.locale)}`, { icon: <FontAwesomeIcon icon="skull" />, iconTheme: { primary: 'var(--rep-danger)', secondary: 'var(--rep-danger)' } })
      return `Translation missing: ${scope}`
    })
    i18n.missingBehavior = 'toast'
  }

  useEffect(() => { i18n.locale = current_user.preferred_locale || i18n.defaultLocale }, [current_user])

  /**
   * @borrows fetchApiWithToken as fetchApi
   * @see {@link fetchApiWithToken}
   */
  const fetchApi = (props: fetchProps) => new Promise((resolve, _reject) => resolve(fetchApiWithToken(token, props)))

  const getReport = id =>
    fetch(`/reports/${id}/show`, { cache: 'no-store' })
      .then(data => data.json())

  const getMinimalReport = (id, costs) =>
    fetch(`/reports/${id}/rep_show_minimal${costs ? '?costs=true' : ''}`, { cache: 'no-store' })
      .then(data => data.json())

  const getConversation = id =>
    fetch(`/conversations/${id}/rep_show`, { cache: 'no-store' })
      .then(data => data.json())

  const getConversations = reportId =>
    fetch(`/reports/${reportId}/conversations/rep_index`, { cache: 'no-store' })
      .then(data => data.json())

  const getRemark = id =>
    fetch(`/control_remarks/${id}/rep_show`, { cache: 'no-store' })
      .then(data => data.json())

  const getRemarks = (id, filters = '') =>
    fetch(`/reports/${id}/rep_remarks.json?${filters}`, { cache: 'no-store' })
      .then(data => data.json())

  const getDocuments = (type, id, filters) =>
    fetch(`/${type}/${id}/rep_documents.json?${filters}`)
      .then(data => data.json())

  const getTask = id =>
    fetch(`/to_do_items/${id}/rep_show`, { cache: 'no-store' })
      .then(data => data.json())

  const getTasks = (id, filters = '') =>
    fetch(`/reports/${id}/rep_tasks.json?${filters}`, { cache: 'no-store' })
      .then(data => data.json())

  const getCost  = (reportId, id) =>
    fetch(`/reports/${reportId}/offer_lines/${id}/rep_show`, { cache: 'no-store' })
      .then(data => data.json())

  const getCosts = (id, filters = '') =>
    fetch(`/reports/${id}/rep_costs.json?${filters}`, { cache: 'no-store' })
      .then(data => data.json())

  // const getMachineStop = reportId => {}

  const initAPI = ({
    type           = null,
    reportId       = null,
    taskId         = null,
    costId         = null,
    remarkId       = null,
    conversationId = null,
  }) => { return {
    fetchReport:        async () => await getReport(reportId),
    fetchMinimalReport: async (costs = false) => await getMinimalReport(reportId, costs),
    fetchTask:          async () => await getTask(taskId),
    fetchTasks:         async filters => await getTasks(reportId, filters),
    fetchCost:          async () => await getCost(reportId, costId),
    fetchCosts:         async filters => await getCosts(reportId, filters),
    fetchConversation:  async () => await getConversation(conversationId),
    fetchConversations: async () => await getConversations(reportId),
    fetchDocuments:     async filters => await getDocuments(type, reportId, filters),
    fetchRemark:        async () => await getRemark(remarkId),
    fetchRemarks:       async filters => await getRemarks(reportId, filters),
    // fetchMachineStop:   async () => await getMachineStop(reportId)
  }}

  return (
    // the Providers gives access to the context to its children
    <GlobalContextState.Provider value={{
      CONSTANTS,
      current_user,
      current_company,
      token,
      i18n,
      fetchApi,
      isDesktop,
      showModal, closeModal,
      modalDisplay,
      modalContent,
      showCommandBar, setShowCommandBar,
      commandBarOptions, setCommandBarOptions,
      showAlert, closeAlert,
      alertDisplay,
      alertActions, alertContent,
      displayInfoWindow,
      openInfoWindow, closeInfoWindow,
      infoResource, setInfoResource,
      infoHistory, setInfoHistory,
      initAPI
    }}>
      {children}
    </GlobalContextState.Provider>
  )
}

export { GlobalContextProvider, useGlobalContextState }
