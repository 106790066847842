import React, { useCallback, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Button from '@components/Button'
import Tag    from '@components/Tag'

import * as Style from './style'

import { useGlobalContextState } from '@context/GlobalContext'

import IWizardForm from './types.d'

const WizardForm: React.FC<IWizardForm> = ({
  forms = [],
  data,
  setData,
  callback,
  buttonValue
}) => {

  const { i18n } = useGlobalContextState()

  const [step, setStep] = useState(0)

  const formRef = useRef(null)

  const renderForm = useCallback(() => React.cloneElement(forms[step].component, { data, setData }), [step, data])

  const changeStep = val => {
    saveData(null)
    setStep(val)
  }

  const saveData = useCallback(event => {
    if (event) event.preventDefault()
    if (formRef.current) {
      const form: FormData = new FormData(formRef.current)
      const formData = Object.fromEntries(form)

      setData(actualData => ({...actualData, ...formData }))
      setStep(step => step + 1)
    }
  }, [step, data])

  const sendForm = () => callback(data)

  return (
    <Style.Wizard>
      <Style.WizardIndex>
        {forms.map((form, key) =>
          <Style.WizardLink
            key      = {key}
            selected = {key === step}
            onClick  = {() => changeStep(key)}
          >
            {form.checkTag.map(key => data[key]).filter(e => {
              if (Array.isArray(e)) { return e.length }
              else if (typeof e === 'object') { return Object.values(e).filter(e => e).length }
              else { return !!e }
            }).length
              ? <Tag color='var(--rep-success)' icon={<FontAwesomeIcon icon="check-circle" />}>{form.name}</Tag>
              : <Tag color='var(--rep-primary)' icon={form.icon}>{form.name}</Tag>
            }
          </Style.WizardLink>
        )}
      </Style.WizardIndex>

      <Style.WizardContent ref={formRef} onSubmit={saveData}>
        <div>
          {renderForm()}
        </div>

        {(step + 1) === forms.length
          ? <Button
            border      = 'var(--rep-success)'
            hover       = 'var(--rep-success-light)'
            color       = 'var(--rep-success)'
            marginY     = 'M'
            size        = 'L'
            icon        = {<FontAwesomeIcon icon="check" />}
            click       = {sendForm}
            fullWidth
          >
            {buttonValue}
          </Button>
          : <Button
            border     = 'var(--rep-primary)'
            hover      = 'var(--rep-primary-light)'
            color      = 'var(--rep-primary)'
            icon       = {<FontAwesomeIcon icon="arrow-right" />}
            click      = {saveData}
            marginY    = 'M'
            size       = 'L'
            fullWidth
          >{i18n.t('shared.next_step')}</Button>
        }
      </Style.WizardContent>
    </Style.Wizard>
  )
}

export default WizardForm
