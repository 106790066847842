import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Checkbox   from '@components/Form/Checkbox'
import Select     from '@form/Select'
import SelectTree from '@components/Form/SelectTree'

import * as Style from './style'

import { useGlobalContextState } from '@context/GlobalContext'
import { useMaintenancesContextState } from '@context/MaintenancesContext'

import * as FormStyle from '@form/FormStyles'

const CalendarFilters = () => {

  const { i18n }                = useGlobalContextState()
  const { filters, setFilters } = useMaintenancesContextState()

  const [autogenerate, setAutogenerate] = useState(filters.autogenerate)
  const [archived,     setArchived]     = useState(filters.archived)

  const [selectedZone, setSelectedZone]       = useState(null)
  const [selectedAmenity, setSelectedAmenity] = useState(null)

  useEffect(() => selectAutogenerate(autogenerate?.value ? autogenerate.value : autogenerate), [autogenerate])
  useEffect(() => selectArchived(archived?.value ? archived.value : archived), [archived])

  const selectLate         = late         => setFilters(oldFilters => ({ ...oldFilters, late: late.isCheck }) )
  const selectLegal        = legal        => setFilters(oldFilters => ({ ...oldFilters, legal: legal.isCheck }) )
  const selectAutogenerate = autogenerate => setFilters(oldFilters => ({ ...oldFilters, autogenerate: autogenerate }) )
  const selectArchived     = archived     => setFilters(oldFilters => ({ ...oldFilters, archived: archived }) )
  const selectAmenityType  = type         => setFilters(oldFilters => ({ ...oldFilters, amenity_type_id: type?.object?.id, amenity_type_name: type?.object?.name }) )
  const selectPlan         = plan         => setFilters(oldFilters => ({ ...oldFilters, plan_id: plan?.value, plan_name: plan?.object?.name }) )
  const selectZone         = zone         => {
    setFilters(oldFilters => ({ ...oldFilters, zone_id: zone?.id, zone_name: zone?.name }) )
    setSelectedZone(zone)
  }
  const selectAmenity      = amenity      => {
    setFilters(oldFilters => ({ ...oldFilters, amenity_id: amenity?.id, amenity_name: amenity?.name }) )
    setSelectedAmenity(amenity)
  }

  return (
    <>
      <Style.ButtonGroup>
        <Select
          name             = 'filter-templates'
          label            = {i18n.t('maintenance.templates')}
          defaultValue     = {[{ name: filters.plan_name, value: filters.plan_id }]}
          callback         = {selectPlan}
          emptyCallback    = {selectPlan}
          searchUrl        = '/maintenance_plans/search'
          format           = {{ content: 'name', value: 'id'}}
          search
          withEmpty
        />
        <Select
          name             = 'filter-autogenerate'
          label            = {i18n.t('maintenance.autogenerated')}
          defaultValue     = {[autogenerate?.value ? autogenerate : { content: null, value: autogenerate }]}
          callback         = {selectedValue => setAutogenerate(selectedValue.object)}
          emptyCallback    = {() => setAutogenerate(null)}
          options          = {[
            { content: i18n.t('maintenance.only_autogenerated'), value: 'true' },
            { content: i18n.t('maintenance.not_autogenerated'), value: 'false' }
          ]}
          withEmpty
        />
        <Select
          name             = 'filter-archived'
          label            = {i18n.t('maintenance.archived_items')}
          defaultValue     = {[archived?.value ? archived : { content: null, value: archived }]}
          callback         = {selectedValue => setArchived(selectedValue.object)}
          emptyCallback    = {() => setArchived(null)}
          options          = {[
            { content: i18n.t('maintenance.filters.with_archived'), value: 'with_archived' },
            { content: i18n.t('maintenance.filters.only_archived'), value: 'only_archived' }
          ]}
          withEmpty
        />
        <Select
          name             = 'filter-amenity-types'
          label            = {i18n.t('amenity.amenity_types')}
          defaultValue     = {[{ name: filters.amenity_type_name, value: filters.amenity_type_id }]}
          callback         = {selectAmenityType}
          emptyCallback    = {selectAmenityType}
          searchUrl        = '/amenity_types/rep_search'
          format           = {{ content: 'name', value: 'id'}}
          search
          withEmpty
        />
      </Style.ButtonGroup>
      <div style={{ display: 'flex', padding: '8px' }}>
        <div style={{ width: '50%' }}>
          <SelectTree
            name           = 'unit'
            label          = {i18n.t('unit.unit')}
            selected       = {[selectedZone]}
            callback       = {selectZone}
            withEmpty
            searchUrl      = '/units/rep_search?path=true'
            filters        = {[{
              id:      'root',
              name:    'Parent',
              filters: { roots: 'true' }
            }]}
            format         = {{ content: 'name', value: 'id' }}
            //  disabled       = {report?.id && !report.permissions.can_update_report}
            marginY        = 'M'
            subFilterName  = 'for_unit'
            subElementName = {i18n.t('unit.subunit')}
          />
          {selectedZone?.has_amenities &&
          <>
            <FormStyle.UnitArrow><FontAwesomeIcon icon="arrow-down" /></FormStyle.UnitArrow>
            <SelectTree
              name      = 'amenity'
              label     = {i18n.t('amenity.amenity')}
              selected  = {[selectAmenity]}
              callback  = {selectAmenity}
              withEmpty = {true}
              searchUrl = '/amenities/rep_search'
              filters   = {[{
                id:      'amenity',
                name:    'Parent',
                filters: { roots: 'true', for_unit: selectedZone.id.toString(), path: 'true' }
              }]}
              format         = {{ content: 'name', value: 'id' }}
              marginY        = 'M'
              subFilterName  = 'for_amenity'
              subElementName = {i18n.t('amenity.subamenity')}
            />
          </>
          }
        </div>
        <div style={{padding: '8px', paddingTop: '16px'}}>
          <Checkbox
            name         = 'late'
            label        = {i18n.t('maintenance.late')}
            defaultValue = {filters.late}
            callback     = {selectLate}
          />
          <Checkbox
            name         = 'legal'
            label        = {i18n.t('maintenance.legal_control')}
            defaultValue = {filters.legal}
            callback     = {selectLegal}
          />
        </div>
      </div>
    </>
  )
}

export default CalendarFilters
