import React, { useEffect, useMemo, useRef, useState } from 'react'

import Button      from '@components/Button'
import Callout     from '@components/Callout'
import Card        from '@components/Card'
import DatePicker  from '@components/Form/DatePicker'
import OngoingTask from '@components/Task/OngoingTask'
import Select      from '@components/Form/Select'
import Tag         from '@components/Tag'
import TaskCard    from '@components/Task/TaskCard'
import TaskLine    from '@components/Task/TaskLine'

import HelloHoustonLogo from './images/badge.svg'

import * as FormStyle from '@components/Form/FormStyles'
import * as Style     from './style'
import * as TaskStyle from '@components/Task/style'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { ITask } from '@interfaces/index'

import { TaskContextProvider  }  from '@context/TaskContext'
import { useGlobalContextState } from '@context/GlobalContext'

import { buildFilterString } from '@utils/filterString'
interface DataProps {
  planned:  ITask[]
  expected: any
}
interface TaskListProps {
  data:              DataProps
  setData:           React.Dispatch<React.SetStateAction<DataProps>>
  serverOngoingTask: ITask
  serverLateCount:   number
}

const VIEW = {
  LATE:       'late',
  CURRENT:    'current',
  SEARCH:     'search',
  UNASSIGNED: 'unassigned'
}

const TaskList: React.FC<TaskListProps> = ({
  data,
  setData,
  serverOngoingTask,
  serverLateCount
}) => {

  const {
    current_company,
    fetchApi,
    i18n,
    setCommandBarOptions,
    isDesktop,
    CONSTANTS
  } = useGlobalContextState()

  const statusOptions = useMemo(() =>
    CONSTANTS.TASK.ONGOING_STATUSES.map(status => ({ value: status, text: i18n.t(`todo.status.${status}`)}))
  , [])

  const ongoingRef = useRef(null)

  const [ongoingTask,        setOngoingTask]        = useState(serverOngoingTask)
  const [displayOngoingTask, setDisplayOngoingTask] = useState(!!serverOngoingTask)
  const [lateCount,          setLateCount]          = useState(serverLateCount)

  const [loading,        setLoading]        = useState(false)
  const [pagination,     setPagination]     = useState({ page: 1, next: 1, last: 0 })
  const [view,           setView]           = useState(new URLSearchParams(window.location.search).get('filter') || VIEW.CURRENT)
  const [searchQuery,    setSearchQuery]    = useState(new URLSearchParams(window.location.search).get('q') || '')
  const [selectedDates,  setSelectedDates]  = useState<Date | Date[]>([new Date()])
  const [showCalendar,   setShowCalendar]   = useState(false)
  const [showFilter,     setShowFilter]     = useState(false)
  const [selectedStatus, setSelectedStatus] = useState([])

  useEffect(() => {
    if (!ongoingRef.current) return
    const observer = new IntersectionObserver(
      ([entry]) => { setDisplayOngoingTask(!entry.isIntersecting) },
      { rootMargin: '-150px' }
    )
    observer.observe(ongoingRef.current)
    return () => observer.disconnect()
  }, [ongoingTask])


  useEffect(() => {
    setData({ expected: [], planned: [ ]})
    setPagination({ page: 1, next: 0, last: 0 })
    fetchPage(1)
    if (view !== VIEW.SEARCH) setSearchQuery('')
  }, [view, selectedStatus, selectedDates])

  const setTasksInfo = (data) => {
    if (data.pagination.page === 1) {
      setData(data)
    } else {
      setData(initial => ({
        ...initial,
        planned: [...initial.planned, ...data.planned]
      }))
    }
    setPagination(data.pagination)
    setOngoingTask(data.ongoing_task)
    setLateCount(data.late_count)
    setLoading(false)
  }

  const fetchPage = (page = null) => {
    if (!page && !pagination.next) return
    setLoading(true)
    const filters = buildFilterString({
      filter: view,
      range:  selectedDates.map(s => s && s.toISOString()).join(','),
      status: selectedStatus.map(s => s && s.value).join(','),
      q:      searchQuery
    })
    window.history.replaceState(null, '', `${window.location.pathname}?${filters}`)

    if (view === VIEW.LATE || view === VIEW.UNASSIGNED) {
      fetchApi({
        url:      `/period_to_todos?${filters}&page=${page || pagination.next}`,
        callback: setTasksInfo
      })
    } else if (view === VIEW.SEARCH) {
      fetchApi({
        url:      `/period_to_todos/rep_search?${filters}&page=${page || pagination.next}`,
        callback: data => setTasksInfo({ planned: data, expected: [], pagination: { page: 1, next: 1, last: 0 }})
      })
    }else {
      fetchApi({
        url:      `/period_to_todos/rep_fetch_calendar?${filters}&page=${page || pagination.next}`,
        callback: showSelectedTasks
      })
    }
  }

  const taskContainerRef = useRef(null)

  const handleScroll = e => {
    // console.log(Math.abs(e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop))
    if (taskContainerRef.current.scrollHeight <= taskContainerRef.current.clientHeight) return
    if (Math.abs(e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop) <= 1) {
      if (loading) return
      fetchPage(null)
    }
  }

  const displayTask = task => {
    setShowCalendar(false)
    setData(_data => ({ planned: [task], expected: [] }))
    setView(VIEW.SEARCH)
    setCommandBarOptions({})
  }

  const showCommandBar = () => {
    setCommandBarOptions({
      url:             '/period_to_todos/rep_search',
      setSearchQuery:  setSearchQuery,
      renderComponent: (key, object) =>
        <TaskLine
          key   = {key}
          task  = {object}
          click = {_event => displayTask(object)}
        />
    })
  }

  const showSelectedTasks = (data) => {
    setData(data)
    setLoading(false)
  }

  const setDayToPrevious = () => {
    if (Array.isArray(selectedDates)) {
      setSelectedDates([new Date(selectedDates[0].setDate(selectedDates[0].getDate() - 1))])
    } else {
      setSelectedDates([new Date(selectedDates.setDate(selectedDates.getDate() - 1))])
    }
    setView(VIEW.CURRENT)
  }
  const setDayToNext = () => {
    if (Array.isArray(selectedDates)) {
      setSelectedDates([new Date(selectedDates[0].setDate(selectedDates[0].getDate() + 1))])
    } else {
      setSelectedDates([new Date(selectedDates.setDate(selectedDates.getDate() + 1))])
    }
    setView(VIEW.CURRENT)
  }

  const selectToday = () => {
    setSelectedDates([new Date()])
    setShowCalendar(false)
    setView(VIEW.CURRENT)
  }

  return (
    <Style.TasksListLayout ref={taskContainerRef} onScroll={handleScroll}>
      <Style.HeaderStyles>
        <Style.Logo>
          <img src={HelloHoustonLogo} onClick={selectToday}/>
        </Style.Logo>
        {view === VIEW.CURRENT &&
        <>
          <Style.DateSelection>
            <Button
              icon   = {<FontAwesomeIcon icon="caret-left" />}
              border = 'var(--rep-primary)'
              click  = {setDayToPrevious}
            />
            <Style.DateSelection>
              <Button
                icon  = {showCalendar ? <FontAwesomeIcon icon="calendar-xmark" /> : <FontAwesomeIcon icon="calendar" />}
                hover = {showCalendar ? 'var(--rep-primary-light)' : null}
                click = {() => setShowCalendar(!showCalendar)}
              >
                {view === VIEW.SEARCH
                  ? '--'
                  : Array.isArray(selectedDates)
                    ? selectedDates.map(d => new Intl.DateTimeFormat(i18n.locale).format(d)).join('->')
                    : new Intl.DateTimeFormat(i18n.locale).format(selectedDates)
                }
              </Button>
            </Style.DateSelection>
            <Button
              icon   = {<FontAwesomeIcon icon="caret-right" />}
              border = 'var(--rep-primary)'
              click  = {setDayToNext}
            />
          </Style.DateSelection>
          {!!showCalendar &&
          <Style.DateCalendar>
            <DatePicker
              name     = 'calendar'
              mode     = 'range'
              date     = {selectedDates}
              callback = {dates => setSelectedDates(dates.map(d => new Date(d)))}
            />
          </Style.DateCalendar>
          }
        </>
        }
        <Style.HeaderTitle>
          <Button
            icon       = {<FontAwesomeIcon icon={'magnifying-glass'} />}
            border     = {view === VIEW.SEARCH ? 'var(--rep-primary-light)' : 'var(--rep-neutral-primary-light)'}
            color      = {view === VIEW.SEARCH ? 'var(--rep-primary)'       : 'var(--rep-neutral-primary)'}
            background = {view === VIEW.SEARCH ? 'var(--rep-primary-light)' : 'white'}
            click      = {showCommandBar}
            fullWidth
          >
            {i18n.t('actions.search_in_your_tasks')}
          </Button>
        </Style.HeaderTitle>
        <Style.FilterList>
          <Button
            icon       = {<FontAwesomeIcon icon='calendar-day' />}
            border     = {view === VIEW.CURRENT ? 'var(--rep-primary-light)' : 'var(--rep-neutral-primary-light)'}
            color      = {view === VIEW.CURRENT ? 'var(--rep-primary)'       : 'var(--rep-neutral-primary)'}
            background = {view === VIEW.CURRENT ? 'var(--rep-primary-light)' : 'white'}
            click      = {() => setView(VIEW.CURRENT)}
          >
            {i18n.t('todo.daily')}
          </Button>
          <Button
            icon       = {<FontAwesomeIcon icon='triangle-exclamation' />}
            border     = {view === VIEW.LATE ? 'var(--rep-primary-light)' : 'var(--rep-neutral-primary-light)'}
            color      = {view === VIEW.LATE ? 'var(--rep-primary)'       : 'var(--rep-neutral-primary)'}
            background = {view === VIEW.LATE ? 'var(--rep-primary-light)' : 'white'}
            click      = {() => setView(VIEW.LATE)}
          >
            {i18n.t('shared.late')} {!!lateCount && <Style.LateNumber>{lateCount}</Style.LateNumber>}
          </Button>
          {current_company.permissions.tech_can_create_tasks &&
            <Button
              icon       = {<FontAwesomeIcon icon='hand-holding-hand' />}
              border     = {view === VIEW.UNASSIGNED ? 'var(--rep-primary-light)' : 'var(--rep-neutral-primary-light)'}
              color      = {view === VIEW.UNASSIGNED ? 'var(--rep-primary)'       : 'var(--rep-neutral-primary)'}
              background = {view === VIEW.UNASSIGNED ? 'var(--rep-primary-light)' : 'white'}
              click      = {() => setView(VIEW.UNASSIGNED)}>
              {i18n.t('todo.unassigned')}
            </Button>
          }
          <Button
            icon   = {<FontAwesomeIcon icon="wand-magic-sparkles" />}
            border = {showFilter || selectedStatus.length ? 'var(--rep-primary)' : 'var(--rep-neutral-primary-light)'}
            color  = {showFilter || selectedStatus.length ? 'var(--rep-primary)' : 'var(--rep-neutral-primary)'}
            click  = {() => setShowFilter(!showFilter)}
          >
            {i18n.t('actions.filter')}
          </Button>
        </Style.FilterList>
        {showFilter &&
          <Style.Filters
            toggled   = {showFilter}
            isDesktop = {isDesktop}
          >
            <Select
              name          = 'status'
              label         = {i18n.t('shared.status')}
              withEmpty     = {true}
              options       = {statusOptions}
              defaultValue  = {selectedStatus}
              callback      = {setSelectedStatus}
              emptyCallback = {() => setSelectedStatus([])}
              format        = {{ content: 'text', value: 'value' }}
              marginY       = 'S'
              multiselect
            />
          </Style.Filters>
        }
      </Style.HeaderStyles>
      <Style.ListStyles>
        {view === VIEW.UNASSIGNED &&
          <FormStyle.Header marginY='S'>{i18n.t('todo.unassigned_title')}</FormStyle.Header>
        }
        {data.planned.length || data.expected.length
          ? <>
            {data.planned.map((task, key) =>
              <TaskContextProvider
                key        = {`${key}-${task.id}`}
                serverTask = {task}
              >
                <TaskCard
                  ongoingTask    = {ongoingTask}
                  ongoingRef     = {ongoingRef}
                  setOngoingTask = {setOngoingTask}
                  changeView     = {setView}
                />
              </TaskContextProvider>
            )}
            {data.expected.map(expected =>
              <Card
                key={`expected-${expected.id}-${expected.maintainable.id}`}
                // border='var(--rep-warning-light)'
                togglable = {false}
                headerTop = {
                  <TaskStyle.TaskHeader>
                    <TaskStyle.TaskStatus status='done'>
                      <FontAwesomeIcon icon="clock-rotate-left" />
                      {i18n.t('maintenance.recurrence_title')}
                    </TaskStyle.TaskStatus>
                    <div></div>
                    <TaskStyle.TaskTopActions>
                      <Tag
                        icon           = {<FontAwesomeIcon icon="calendar-xmark" />}
                        color          = 'var(--rep-warning)'
                        background     = 'var(--rep-warning-light)'
                        tooltipContent = "Expected for"
                      >
                        {new Date(expected.date).toLocaleDateString(i18n.locale)}
                      </Tag>
                      {!!expected.duration &&
                      <Tag
                        icon           = {<FontAwesomeIcon icon="hourglass" />}
                        color          = 'var(--rep-neutral-primary)'
                        background     = 'var(--rep-neutral-light)'
                        hover          = 'var(--rep-neutral-primary-light)'
                        tooltipContent = {i18n.t('todo.expected_duration')}
                      >
                        {expected.duration_string}
                      </Tag>
                      }
                    </TaskStyle.TaskTopActions>
                  </TaskStyle.TaskHeader>
                }
                header={
                  <>
                    <TaskStyle.TaskTitle>{expected.name}</TaskStyle.TaskTitle>
                    <TaskStyle.TaskText>{expected.description}</TaskStyle.TaskText>
                  </>
                }
              >
                {/* <div style={{color: 'var(--rep-warning)'}}> */}
                <Callout
                  color="var(--rep-warning)"
                  background="var(--rep-warning-light)"
                  icon={<FontAwesomeIcon icon="calendar-xmark" />}
                  border='transparent'
                  marginY='S'
                >
                  {i18n.t('todo.maintenance_not_generated')}
                </Callout>
                {/* </div> */}
                <div style={{display: 'flex', gap: '8px', overflowX: 'scroll', scrollbarWidth: 'none', marginTop: '8px', marginBottom: '8px'}}>
                  <Tag
                    // border='var(--rep-warning)'
                    color      = 'var(--rep-neutral)'
                    background = 'var(--rep-neutral-light)'
                    icon       = {expected.maintainable_type === 'Amenity'
                      ? <FontAwesomeIcon icon="gear" />
                      : expected.maintainable_type === 'Unit'
                        ? <FontAwesomeIcon icon="location-dot" />
                        : null}
                  >
                    {expected.maintainable.name}
                  </Tag>
                  <Tag
                    color      = 'var(--rep-neutral)'
                    background = 'var(--rep-neutral-light)'
                    icon       = {<FontAwesomeIcon icon="file-contract" />}
                  >
                    {expected.plan_name}
                  </Tag>
                </div>
              </Card>
            )}
          </>
          : !loading &&
          <Style.NoMoreTasks>
            {view === VIEW.LATE &&
              <>
                <FontAwesomeIcon icon="ranking-star" />
                {i18n.t('todo.page.no_late')}
              </>
            }
            {view === VIEW.CURRENT &&
              <>
                <FontAwesomeIcon icon="person-circle-question" />
                {i18n.t('todo.page.no_ongoing')}
              </>
            }
            {view === VIEW.UNASSIGNED &&
              <>
                <FontAwesomeIcon icon="child-reaching" />
                {i18n.t('todo.page.no_unassigned')}
              </>
            }
          </Style.NoMoreTasks>
        }
        {!!ongoingTask && displayOngoingTask &&
          <TaskContextProvider
            key        = {ongoingTask.id}
            serverTask = {ongoingTask}
          >
            <OngoingTask
              ongoingTask    = {ongoingTask}
              setOngoingTask = {setOngoingTask}
              taskRef        = {ongoingRef}
              setView        = {setView}
              setData        = {setData}
            />
          </TaskContextProvider>
        }
      </Style.ListStyles>
      {loading  && <Style.Loading><Style.LoadingIcon><FontAwesomeIcon icon="spinner" spin /></Style.LoadingIcon></Style.Loading>}
      {!!pagination.last && (pagination.page === pagination.last) &&
        <Style.NoMoreTasks>
          <FontAwesomeIcon icon="hand-point-up" />
          {i18n.t('todo.page.no_more')}
        </Style.NoMoreTasks>
      }
    </Style.TasksListLayout>
  )
}

export default TaskList
