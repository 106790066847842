import styled from 'styled-components'

export const Checkbox = styled.div`
  display:     flex;
  align-items: center;
  width:       100%;
  padding:     4px;
  color:  ${props => props.disabled ? 'var(--rep-neutral)' : props.valid ? 'var(--rep-success)' : 'var(--rep-primary)'};
  &:hover { cursor: pointer; }
`

export const Check = styled.div`
  margin-right: 8px;
`

export const Container = styled.form`
  padding:       8px;
  border-radius: 4px;
  border:        1px solid ${props => props.notApplicable ? 'var(--rep-neutral)' : props.valid ? 'var(--rep-success)' : props.notApplicable ? 'var(--rep-neutral)' : 'var(--rep-primary)'};
  margin:        8px 0;
  background:   ${props => props.notApplicable ? 'var(--rep-neutral-primary-light)' : 'none'};
`

export const NA = styled.div`
  display:          flex;
  flex-direction:   column;
  justify-content:  center;
  align-items:      center;
  border:           1px solid ${props => props.active ? 'none' : 'var(--rep-neutral)'};
  color:            var(--rep-neutral);
  padding:          10px;
  border-radius:    5px;
  margin:           16px 0;
  background:       white;
  text-align:       center;
  > p     { margin: 0 }
  &:hover { cursor: pointer }
`
